import service from "@/utils/requestFn";
// 政策
export function publicyfn (){
    return service({
        url:'/system/policies/half/year',
        method:'get'
    })
}
// 三公
export function threeDatafn () {
    return service({
        url:'/system/publicing/half/year',
        method:'get'
    })
}
// 党建动态总数
export function dangjianTotal () {
    return service({
        url:'/system/dynamics/total',
        method:'get'
    })
}
// 党建动态趋势
export function dangjianTrend () {
    return service({
        url:'/system/dynamics/half/year',
        method:'get'
    })
}
// 党建最新动态
export function Newdangjian(pageNum,pageSize) {
    return service({
        url:`/system/dynamics/list?pageNum=${pageNum}&pageSize=${pageSize}`,
        method:'get'
    })
}