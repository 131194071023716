<template>
  <div class="page-bg">
    <div class="content-bg">
      <div class="item-width">
        <div style="height:90vh;overflow:scroll">
        <div class="page-item1">
          <div class="item-title">移动用户数据</div>
          <div class="pic-items">
            <div class="pic-item" @click="openDialog('用户总数',countData.totalNum + '（人）')">
              <img src="@/assets/renRenTong/icon (10).png" alt="icon">
              <div class="right">
                <div class="name">用户总数</div>
                <div class="num">{{ countData.totalNum ? countData.totalNum : 0 }}<span class="unit">（人）</span></div>
              </div>
            </div>
            <div class="pic-item" @click="openDialog('今日新增用户数',countData.todayNum + '（人）')">
              <img src="@/assets/renRenTong/icon (1).png" alt="icon">
              <div class="right">
                <div class="name">今日新增用户数</div>
                <div class="num">{{ countData.todayNum ? countData.todayNum : 0 }}<span class="unit">（人）</span></div>
              </div>
            </div>
          </div>
          <div class="sub-title"><span class="dot"></span>用户最近一周新增趋势</div>
          <div id="echarts1"></div>
        </div>

        <div class="page-item2">
          <div class="item-title">广播电视节目播出套数</div>
          <div id="echarts2"></div>
        </div>

        <div class="page-item3">
          <div class="item-title">热点资讯</div>
          <div class="video-box">
            <video :src="itemdata3" controls="controls" autoplay="autoplay" loop="loop" muted></video>
          </div>
        </div>

        <div class="page-item3">
          <div class="item-title">热点跟踪</div>
          <div id="redianEcharts"></div>
        </div>

        <div class="page-item3">
          <div class="item-title">热点事件跟踪</div>
          <div class="trtables">
              <th>
                <td>标题</td>
                <td>事件内容</td>
              </th>
              <tr v-for="(item,index) in eventlist" :key="index" @click="openDialog('热点事件跟踪',item.content,'')">
                  <td>{{item.title}}</td>
                  <td>{{item.content}}</td>
              </tr>
            </div>
        </div>
        </div>
      </div>
      <div class="item-width">
        <div style="height:90vh;overflow:scroll">
        <div class="page-item4">
          <div class="item-title-right">广播电视播出机构</div>
          <div class="items-5">
            <div class="items" @click="openDialog('广播电视台',itemdata4.find(fd => fd.dictLabel == '广播电视台').dictValue+' 座')">
              <div class="num" v-if="itemdata4.length">
              {{ itemdata4.find(fd => fd.dictLabel == '广播电视台').dictValue
              }}<span class="unit">座</span>
              </div>
              <div class="name">广播电视台</div>
            </div>
            <div class="items" @click="openDialog('电视台',itemdata4.find(fd => fd.dictLabel == '电视台').dictValue+' 座')">
              <div class="num" v-if="itemdata4.length">
              {{ itemdata4.find(fd => fd.dictLabel == '电视台').dictValue }}<span
                  class="unit">座</span>
              </div>
              <div class="name">电视台</div>
            </div>
            <div class="items" @click="openDialog('融媒体中心',itemdata4.find(fd => fd.dictLabel == '融媒体中心').dictValue+' 个')">
              <div class="num" v-if="itemdata4.length">
              {{ itemdata4.find(fd => fd.dictLabel == '融媒体中心').dictValue
              }}<span class="unit">个</span>
              </div>
              <div class="name">融媒体中心</div>
            </div>
          </div>
        </div>
        <div class="page-item5">
          <div class="item-title-right">从业人员情况</div>
          <div class="item5-content">
            <div class="all-count" 
            @click="openDialog('从业人员',itemdata5.find(fd => fd.dictLabel == '从业人员').dictValue+' 万人')">
              <img class="bg-img" src="@/assets/renRenTong/icon (8).png" alt="bg">
              <div class="name">从业人员</div>
              <div class="num" v-if="itemdata5.length">
                {{ itemdata5.find(fd => fd.dictLabel == '从业人员').dictValue }}<span
                class="unit">万人</span>
              </div>
            </div>
            <div @click="openSexDialog('从业人员-性别',itemdata5)">
              <div class="rote-info">
                <div class="name">男职工</div>
                <div class="rote-bg c1">
                  <div class="value" v-if="itemdata5.length"
                    :style="{ width: (8 * (itemdata5.find(fd => fd.dictLabel == '男职工').dictValue) / 100) + 'vw' }">{{
                        itemdata5.find(fd =>
                          fd.dictLabel == '男职工').dictValue
                    }}%</div>
                </div>
              </div>
              <div class="rote-info">
                <div class="name">女职工</div>
                <div class="rote-bg c2">
                  <div class="value" v-if="itemdata5.length"
                    :style="{ width: (8 * (itemdata5.find(fd => fd.dictLabel == '女职工').dictValue) / 100) + 'vw' }">{{
    itemdata5.find(fd =>
      fd.dictLabel == '女职工').dictValue
                    }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-item5">
          <div class="item-title-right">资讯分类</div>
          <div id="echarts3zx" @click="zxfxDailog('资讯分类')">
          </div>
        </div>
        <div class="page-item6">
          <div class="item-title-right">节目信息统计</div>
          <div id="echarts3"></div>
        </div>
        </div>
      </div>
    </div>
    <com-dialog :dialogVisible="dialogVisible" :contentInfo="dialogContent" 
    :richText="richText"
    :title="dialogtitle" @cancle="dialogVisible = false"></com-dialog>
  </div>
</template>
<script>
import { staticFn } from "@/api/tourism/api";
import {getINFO} from '@/api/common'
import { tong_data, tong_week } from "@/api/renRenTong/index";
import comDialogMinx from "./minixs";
import {getFutureDates} from '@/utils/comonfn'
let myEcharts1, myEcharts2, myEcharts3, echarts3zx, redianEcharts;
export default {
  mixins: [comDialogMinx],
  data() {
    return {
      countData: {},
      weekData: [],
      guangbo: [],
      itemdata3: "",
      itemdata4: [],
      itemdata5: [],
      itemdata6: [],
      isshow: false,
      title: "热点跟踪展示",
      contents: "",
      list: [
        {
          title: "又是一年“香肠季” 你钟意什么口...",
          content: `大过年的日子，还是要有肉相配才够隆重。
          年关将近，不少市民已经循着老底子的惯例，
          着手准备新年餐桌上的腊肠、酱肉等肉食。`,
        },
        {
          title: "严打私屠滥宰 筑牢肉品质量安全防...",
          content: `猪牛羊肉等动物产品质量安全隐患易发多发，为切实保障春节期间市场安全稳定，
          连日来市场监督管理局立足工作实际，
          精心组织，主动出击，积极开展整治私屠滥宰专项检查行动
          ，严厉打击私屠滥宰、销售未经检疫的畜禽产品等违法违规行为，切实维护市场秩序和消费者合法权益。`,
        },
        {
          title: "品种改良促增收 提质增效助发展",
          content: `牛产业快速发展，关键在品种改良。近年来，
          安定区将品种改良作为肉牛产业发展的关键举措，通过实
          施畜牧良种补贴项目、加强技术宣传培训等一系列措施，加
          快推进全区肉牛产业由“增量型”向“提质型”转变，实现牛产
          业高质量发展，为乡村振兴赋能。`,
        },
      ],
      eventlist: [
        {
          title: "2024年新生儿遗传代谢病及“两癌”...",
          content: `中新网四川新闻1月18日电 (记者 王鹏)记者近日从成都市妇女儿童中心医院获悉，今年起成
          都市助产机构内出生的所有活产儿(含非户籍)遗传代谢病筛查病种增多，且女性“两癌”筛查范围
          扩大，全市妇女儿童健康保障进一步加强。`,
        },
        {
          title: "打击整治网络谣言 成都警方公布4起...",
          content: `为维护良好网络舆论秩序，有效净化网络环境，切实提升广大人民群众
          上网安全感和满意度。公安部决定将2024年作为“打击整治网络谣言”专项行动年。
        成都公安积极响应，主动出击，全面梳理摸排网上造谣生事、恶意炒作线索，依法严厉打击整治
        网络谣言违法犯罪活动，整治网络乱象，营造清朗网络空间。`,
        },
      ],
      richText: "",
      zxData:{}
    };
  },
  computed: {
    countWidth() {
      let nan = this.itemdata5.find((fd) => fd.dictLabel == "男职工");
      return (156 * nan.dictValue) / 100;
    },
  },
  mounted() {
    window.addEventListener("resize", function () {
      myEcharts1.resize();
      myEcharts2.resize();
      myEcharts3.resize();
      echarts3zx.resize();
      redianEcharts.resize()
    });
    this.tong_week();
    this.programs_broadcast_num("programs_broadcast_num");
    this.program_production("program_production");
    this.getALLData()
  },
  created() {
    this.tong_data();
    this.getItemdata4("radio_television_broadcasting_institutions");
    this.getItemdata5("employees");
    this.getItemdata3("hotspot_information_video");
  },
  methods: {
    zxfxDailog(title){
      let richText = `
        <div>法律：${this.zxData.fl}</div>
        <div>经济：${this.zxData.jj}</div>
        <div>政治：${this.zxData.zz}</div>
        <div>科技：${this.zxData.kj}</div>
      `
      this.openDialog(title,'',richText)
    },
    openSexDialog(title,itemdata5){

      let total = itemdata5.find(fd => fd.dictLabel == '从业人员').dictValue
      let MAN = itemdata5.find(fd => fd.dictLabel == '男职工').dictValue/100 * total
      let WOMAN = itemdata5.find(fd => fd.dictLabel == '女职工').dictValue/100 * total
      let richText = 
      `
        <div>男职工：<span>${MAN}</span>万人</div>
        <div style="margin-top:10px">女职工：<span>${WOMAN}</span>万人</div>
      `
      this.openDialog(title,'',richText)
    },
    getALLData(){
      Promise.all([
        getINFO('/system/zxfl/one'),//资讯分类
        getINFO('/system/rdsjgz/list'),//热点事件跟踪
        getINFO('/system/rdsjgz/rdgz'),// 热点跟踪
      ]).then(res => {
          this.zxEcharts(res[0].data.data)
          this.zxData = res[0].data.data
          this.eventlist = res[1].data.rows
          this.getredEcharts(res[2].data.data)
      })
    },
    getredEcharts(datas) {
      let arry = getFutureDates()
      let valuedata = datas.map(item => item.num)
      let option = {
        tooltip: {
            trigger: 'item',
            show: true,
        },
        series: [
            {
              name: '',
              type: 'pie',
              radius: '70%',
              data: [
                  { value: valuedata[0], name: arry[0], select: { itemStyle: { shadowColor: '#5470c6' } } },
                  { value: valuedata[1], name: arry[1], select: { itemStyle: { shadowColor: '#91cc75' } } },
                  { value: valuedata[2], name: arry[2], select: { itemStyle: { shadowColor: '#fac858' } } },
                  { value: valuedata[3], name: arry[3], select: { itemStyle: { shadowColor: '#ee6666' } } },
                  { value: valuedata[4], name: arry[4], select: { itemStyle: { shadowColor: '#73c0de' } } },
                  { value: valuedata[5], name: arry[5], select: { itemStyle: { shadowColor: '#5c7bd9' } } },
                  { value: valuedata[6], name: arry[6], select: { itemStyle: { shadowColor: '#74c0de' } } }
              ],
              label: {
                normal: {
                   color: "#fff",
                   fontSize:14
                }
              },
              selectedMode: true,
              selectedOffset: 10,
              select: {
                  itemStyle: {
                    shadowBlur: 10
                  }
              },
              labelLine: {
                  show: false
              },
              emphasis: {
                  disabled: true,
              }
            }
        ]
      }
      redianEcharts = this.$echarts.init(
        document.getElementById("redianEcharts")
      );
      redianEcharts.setOption(option);
    },
    // echarts3zx
    zxEcharts(datas) {
      let result = [
        {name:'法律',value:datas.fl},
        {name:'经济',value:datas.jj},
        {name:'政治',value:datas.zz},
        {name:'科技',value:datas.kj},
      ]
      let option = {
        tooltip: {
          show: true,
          trigger: 'item',
          padding: [8, 8],
          backgroundColor: 'rgba(12, 51, 115,0.8)',
          borderColor: 'rgba(3, 11, 44, 0.5)',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)'
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: '5%',
          right: '16%',
          top: '15%',
          bottom: '8%',
        },
        xAxis: [
          {
            splitLine: {
              show: false,
            },
            type: 'value',
            show: false,
          },
        ],
        yAxis: [
          {
            type: 'category',
                inverse: true,
                offset: 70,
                position: 'left',
                axisLabel: {
                  show: true,
                  align: 'left',
                  textStyle: {
                    color: '#fff',
                    fontSize: 14,
                  },
                  formatter: function (value, index) {
                    var num = '';
                    var str = '';
                    num = index + 1;
                    str = '{name|' + result[index].name + '}';
                    return str;
                  },
                  rich: {
                    name: { color: '#fff', fontSize: 14, padding: [40, 130, 2, 80] },
                  },
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
            data: result.map((item)=>item.name),
          
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              align: 'left',
              textStyle: {
                color: '#fff',
                fontSize: 12,
              },
              formatter: function (value, index) {
                    var num = '';
                    var str = '';
                    num = index + 1;
                    str = '{name|' + result[index].value + '}';
                    return str;
                  },
                  rich: {
                    name: { color: '#fff', fontSize: 14, padding: [40, 0, 2, 0] },
                  },
            },
            data: result.map((item)=>item.value),
          },
        ],
        series: [
          {
            name: '率',
            type: 'bar',
            barWidth: 10, // 柱子宽度
            MaxSize: 0,
            showBackground: true,
            backgroundStyle: {
              color:'rgba(79, 98, 114, 0.6)',
            },
            label: {
              show: false,
            },
            data: result.map((item,index)=>{
              return{
                name:item.name,
                value:item.value,
                itemStyle: {
              color: {
                x: 1,
                y: 0,
                x2: 0,
                y2: 0,
                colorStops: [{
                  offset: 0,
                  color: '#1CEBA0' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#11CDEB' // 100% 处的颜色
                }],
              },
              borderRadius: 0,
            },
              }
            }),
          },
          {
            name: "外圆",
            type: "scatter",
            emphasis: {
              scale: false,
            },
            symbol: "rect",
            symbolSize: [5,16],
            itemStyle: {
              color: '#FFF',
              shadowColor: "rgba(255, 255, 255, 0.5)",
              shadowBlur: 5,
              borderWidth: 1,
              opacity: 1,
            },
            z: 2,
            data: result,
            animationDelay: 500,
          },
        ],
      };
      echarts3zx = this.$echarts.init(document.getElementById("echarts3zx"));
      echarts3zx.setOption(option);
    },
    async program_production(type) {
      const res = await staticFn(type);
      this.itemdata6 = res.data.data;
      this.echarts3();
    },
    async getItemdata3(type) {
      const res = await staticFn(type);
      this.itemdata3 = res.data.data[0].dictValue;
    },
    async getItemdata5(type) {
      const res = await staticFn(type);
      this.itemdata5 = res.data.data;
    },
    async getItemdata4(type) {
      const res = await staticFn(type);
      this.itemdata4 = res.data.data;
    },
    async programs_broadcast_num(type) {
      const res = await staticFn(type);
      this.guangbo = res.data.data;
      this.echarts2();
    },
    async tong_data() {
      const res = await tong_data();
      this.countData = res.data.data;
    },
    async tong_week() {
      const res = await tong_week();
      this.weekData = res.data.data;
      this.echarts1();
    },
    echarts1() {
      myEcharts1 = this.$echarts.init(document.getElementById("echarts1"));

      let xLabel = this.weekData.map((item) => {
        return item.date;
      });
      let femaleNumber = this.weekData.map((item) => {
        return item.femaleNumber;
      }); //#女性

      let maleNumber = this.weekData.map((item) => {
        return item.maleNumber;
      }); //#男性
      let option = {
        grid: {
          top: "20%",
          left: "17%",
          right: "20%",
          bottom: "20%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(4, 243, 247, 0.2)",
          borderColor: "rgba(4, 243, 247, 0.2)",
          textStyle: {
            color: "#ffffff",
          },
        },
        xAxis: [
          {
            data: xLabel,
            type: "category",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#C7D6F0",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#2D3C5C",
              },
            },
          },
        ],
        yAxis: [
          {
            name: "单位(人)",
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
            },
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#2D3C5C",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#2D3C5C",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#C7D6F0",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "女性",
            type: "line",
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 8,
            lineStyle: {
              normal: {
                width: 1,
                color: "#4EE4FF", // 线条颜色
              },
              type: "solid",
            },
            itemStyle: {
              color: "rgba(78, 228, 255, 0.5)",
              borderWidth: 2,
              borderColor: "#4EE4FF",
            },
            label: {
              show: false,
            },
            data: femaleNumber,
          },
          {
            name: "男性",
            type: "line",
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 8,
            lineStyle: {
              normal: {
                width: 1,
                color: "#FF953F", // 线条颜色
              },
              type: "solid",
            },
            itemStyle: {
              color: "rgba(255, 149, 63, 0.5)",
              borderWidth: 2,
              borderColor: "#FF953F",
            },
            label: {
              show: false,
            },
            data: maleNumber,
          },
        ],
      };
      myEcharts1.setOption(option);
    },
    echarts2() {
      myEcharts2 = this.$echarts.init(document.getElementById("echarts2"));
      let xLabel = this.guangbo.map((item) => {
        return item.dictLabel;
      });
      let goToSchool = this.guangbo.map((item) => {
        return item.dictValue;
      });
      let option = {
        grid: {
          top: "20%",
          left: "17%",
          right: "20%",
          bottom: "20%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(4, 243, 247, 0.2)",
          borderColor: "rgba(4, 243, 247, 0.2)",
          textStyle: {
            color: "#ffffff",
          },
        },
        xAxis: [
          {
            data: xLabel,
            type: "category",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#C7D6F0",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#2D3C5C",
              },
            },
          },
        ],
        yAxis: [
          {
            splitLine: {
              show: true,
              lineStyle: {
                color: "#2D3C5C",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#2D3C5C",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#C7D6F0",
              },
              formatter: "{value}套",
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "bar",
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#47A2FF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1864FF", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: goToSchool,
          },
        ],
      };
      myEcharts2.setOption(option);
    },
    echarts3() {
      myEcharts3 = this.$echarts.init(document.getElementById("echarts3"));

      let datas = this.itemdata6.map((item) => {
        return {
          name: item.dictLabel,
          value: item.dictValue,
        };
      });

      let option = {
        color: ["#2BB1FF", "#FF7F93", "#E786F7", "#24E3C3", "#FFA768"],
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/>{c} ({d}%)",
          backgroundColor: "rgba(4, 243, 247, 0.2)",
          borderColor: "rgba(4, 243, 247, 0.2)",
          textStyle: {
            color: "#ffffff",
          },
        },
        title: {
          text: "单位：（万小时）",
          right: "10%",
          top: "10",
          textStyle: {
            fontSize: 14,
            color: "#FFFFFF",
          },
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: datas,
            roseType: "area",
          },
        ],
      };
      myEcharts3.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

@keyframes fadeinR {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeinL {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.page-bg {
  width: 100vw;
  text-align: left;
  position: relative;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  .content-bg {
    display: flex;
    justify-content: space-between;
    z-index: 888;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.8) 55%);
    .item-width {
      pointer-events: auto;
      @include wd(480);
      // padding: 1vw;
      box-sizing: border-box;
    }
  }
}

.item-title {
  @include pl(44);
  @include pt(10);
  @include pb(10);
  @include font(18);
  @include lh(18);
  font-weight: bold;
  font-style: italic;
  color: #04f3f7;
}

.item-title-right {
  @include pr(54);
  @include pt(10);
  @include pb(10);
  @include font(18);
  @include lh(18);
  font-weight: bold;
  font-style: italic;
  color: #04f3f7;
  text-align: right;
}

.sub-title {
  @include font(16);
  font-weight: 500;
  color: #04f3f7;
  @include ml(30);
  @include mt(20);
  display: flex;
  align-items: center;

  .dot {
    display: inline-block;
    @include height(15);
    @include wd(4);

    background: #04f3f7;
    border-radius: 2px;
    @include mr(4);
  }
}

.video-box {
  @include wd(344);
  @include height(149);
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  overflow: hidden;
  margin: 10px auto;

  video {
    width: 100%;
    height: 100%;
  }
}
.page-item6 {
  @include height(373);
  @include mt(40);
  @include backimage("@/assets/renRenTong/icon (7).png");
  animation: fadeinR 2s;
  // @include mr(20);
}

.page-item5 {
  @include height(271);
  @include mt(30);
  @include backimage("@/assets/renRenTong/icon (6).png");
  animation: fadeinR 2s;
  // @include mr(20);
  .item5-content {
    display: flex;
    @include pt(40);
    @include pr(40);
    @include pl(40);
    @include pb(40);
  }

  @keyframes rotateBg {
    0% {
      transform: rotateZ(0);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  .all-count {
    @include wd(152);
    @include height(152);
    color: #ffffff;
    text-align: center;
    @include mr(30);
    position: relative;

    .bg-img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      animation: rotateBg 5s linear infinite;
    }

    .name {
      @include font(12);
      @include pt(38);
    }

    .num {
      @include font(22);
      font-weight: bold;
      @include mt(5);

      .unit {
        @include font(10);
        font-weight: normal;
      }
    }
  }

  .rote-info {
    display: flex;
    align-items: center;

    .name {
      @include font(12);
      font-weight: 500;
      color: #ffffff;
      @include mr(10);
      white-space: nowrap;
    }

    .rote-bg {
      width: 7.5vw;
      @include height(28);
      @include mt(15);
      @include mb(15);
      border-radius: 14px;

      .value {
        @include font(12);
        color: #ffffff;
        @include height(28);
        @include lh(28);
        text-align: right;
        border-radius: 14px;
      }
    }

    .c1 {
      background: linear-gradient(
        0deg,
        rgba(62, 119, 247, 0.3) 0%,
        rgba(93, 91, 239, 0.3) 100%
      );

      .value {
        background: linear-gradient(
          0deg,
          rgba(62, 119, 247, 1) 0%,
          rgba(93, 91, 239, 1) 100%
        );
      }
    }

    .c2 {
      background: linear-gradient(
        0deg,
        rgba(7, 202, 204, 0.3) 0%,
        rgba(5, 193, 104, 0.3) 100%
      );

      .value {
        background: linear-gradient(
          0deg,
          rgba(7, 202, 204, 1) 0%,
          rgba(5, 193, 104, 1) 100%
        );
      }
    }
  }
}

.page-item4 {
  @include height(228);
  @include backimage("@/assets/renRenTong/icon (5).png");
  animation: fadeinR 2s;
  @include mt(80);
  // @include mr(20);
  .items-5 {
    display: flex;
    align-items: center;
    justify-content: center;
    .items {
      @include wd(131);
      @include height(134);
      font-weight: 500;
      @include mr(5);
      @include mr(5);

      color: #ffffff;
      text-align: center;
      text-shadow: 1px 1px 1px #04f3f7;
      @include backimage("@/assets/renRenTong/icon (9).png");
      cursor: pointer;
      .num {
        @include font(21);
        @include mt(45);
        @include pr(20);

        .unit {
          @include font(12);
        }
      }

      .name {
        @include font(13);
        @include pr(15);
        @include mt(2);
      }
    }
  }
}

.page-item2 {
  @include height(235);
  @include mt(40);
  @include backimage("@/assets/renRenTong/icon (3).png");
  animation: fadeinL 2s;
}

.page-item3 {
  @include height(219);
  @include mt(40);
  @include backimage("@/assets/renRenTong/icon (4).png");
  animation: fadeinL 2s;
}

.page-item1 {
  @include height(429);
  @include backimage("@/assets/renRenTong/icon (2).png");
  animation: fadeinL 2s;
  @include mt(80);
  cursor: pointer;
  .pic-items {
    display: flex;
    @include mt(30);
    @include ml(35);

    .pic-item {
      display: flex;
      align-items: center;
      flex: 1;

      img {
        @include wd(74);
        @include height(50);
      }

      .right {
        @include ml(5);
        flex: 1;

        .name {
          @include font(14);
          color: #ffffff;
          @include lh(9);
          @include mb(5);

          &::after {
            content: " ";
            @include height(9);
            @include wd(94);
            background: linear-gradient(
              90deg,
              rgba(3, 182, 221, 0.5) 1%,
              rgba(4, 245, 247, 0) 100%
            );
            float: left;
            @include mt(-8);
          }
        }

        .num {
          @include font(22);
          font-weight: bold;
          color: #04f5f7;

          .unit {
            @include font(12);
            font-weight: normal;
            color: #aed4d4;
          }
        }
      }
    }
  }
}

#echarts1 {
  @include height(250);
  width: 100%;
}

#echarts2 {
  @include height(190);
  width: 100%;
}

#echarts3 {
  @include height(300);
  width: 100%;
}
#echarts3zx {
  @include height(190);
  width: 100%;
}
::-webkit-scrollbar {
  border-radius: 5px;
  height: 10px;
  background-color: transparent;
  display: none !important;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  display: none !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
  display: none !important;
}
::v-deep .el-dialog {
  background-color: transparent;
  box-shadow: none;
}
.dialogcontent {
  border: 1px solid #04b8bb;
  padding: 20px;
  background-color: rgba(40, 72, 77, 0.3);
  .flexs {
    display: flex;
    justify-content: space-between;
  }
  .title {
    color: #04f3f7;
  }
  img {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}
.uls {
  color: #01f0ff;
  @include pl(20);
  @include pr(20);
  @include pt(20);
  @include font(14);
  box-sizing: border-box;
  cursor: pointer;
  li {
    @include mt(20);
    text-align: left;
    @include ml(20);
  }
}
.trtables {
  height: 90%;
  @include pt(10);
  @include pl(50);
  @include pr(50);
  box-sizing: border-box;
  overflow-y: scroll;
  cursor: pointer;
  th,
  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include height(40);
    @include lh(40);
    text-align: center;
  }
  th {
    color: #04f3f7;
    @include font(14);
  }
  tr {
    color: #dffdff;
    @include font(12);
  }
  td {
    width: 25%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .iamgs {
    display: inline-block;
    @include wd(24);
    @include height(24);
    @include br(2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
#redianEcharts {
  width: 100%;
  height: 90%;
}
</style>