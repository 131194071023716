<template>
  <div class="maps">
    <div id="gao-de-map"></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { addressOne } from "@/api/index";
import {getINFO} from '@/api/common'
// import createInfoWindow from "@/utils/amap";
// 设置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: "b63295dafddf474196cf8e473e68787c",
};
export default {
  name: "gaodeMap",
  data() {
    return {
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
      polygons: [],
      lnglats: [
        [104.269268, 30.2767],
        [104.446504, 30.307995],
        [104.25494, 30.350957],
        [104.518357, 30.353619],
        [104.547644, 30.410937],
        [104.364194,30.478986]
      ],
      infoWindow: null,
      winInfo: [],
    };
  },
  mounted() {
    this.getLanGO()
  },
  beforeDestory () {
    this.$bus.$off('bus-event') // 取消 bus-event 事件
  },
  methods: {
    getLanGO(){
      getINFO('/system/address/getPosition').then(res => {
        this.lnglats = res.data.data
        this.initMap();
      })
    },
    initMap() {
      AMapLoader.load({
        key: "da3340bc2d64e05668726785bfcdafcf",
        version: "2.0",
        plugins: ["AMap.Scale", "AMap.ImageLayer"],
      })
        .then((AMap) => {
          this.infoWindow = new AMap.InfoWindow({
            isCustom: true, //使用自定义窗体
            content: [],
            offset: new AMap.Pixel(0, -40),
          });
          var satellite = new AMap.TileLayer.Satellite();
          var RoadNet = new AMap.TileLayer.RoadNet();
          var imageLayer = new AMap.ImageLayer({
            url: require("@/assets/map.png"),
            bounds: new AMap.Bounds(
              [104.249479, 30.6811],
              [104.946592, 30.053757]
            ),
            zooms: [0, 20],
          });
          this.map = new AMap.Map("gao-de-map", {
            viewMode: "2D",
            zoom: 10.8,
            center: [104.550339, 30.390665], //中心点坐标 简阳市
            resizeEnable: true,
            layers: [satellite, RoadNet, imageLayer],
            features: ["point", "bg"],
            zoomEnable: false,
            dragEnable: true,
          });
          this.lnglats.map((i) => {
            let marker = new AMap.Marker({
              position: i,
              offset: new AMap.Pixel(2, -5),
            });
            marker.on("click", this.markerClick);
            marker.setMap(this.map);
          });
          this.map.on("click", function (evt) {
            console.log(evt, "------");
          });
          RoadNet.setOpacity(0);
          // 获取边界坐标点
          AMap.plugin("AMap.DistrictSearch", () => {
            var districtSearch = new AMap.DistrictSearch({
              // 关键字对应的行政区级别，共有5种级别
              level: "district",
              //  是否显示下级行政区级数，1表示返回下一级行政区
              subdistrict: 0,
              // 返回行政区边界坐标点
              extensions: "all",
            });
            // districtSearch.search('简阳市', (status, result) => {
            //     // 查询成功时，result即为对应的行政区信息
            //     console.log(result)
            //     this.handlePolygon(result)
            // })
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    markerClick(e) {
      addressOne(e.target._position[0], e.target._position[1]).then((res) => {
        if (res.data.code == 200) {
          // 提交事件
          this.$bus.$emit('bus-event', {
            longitude:e.target._position[0],
            latitude:e.target._position[1]
          })
          let that = this
          // 设置窗体内容
          this.infoWindow.setContent(
            this.createInfoWindow(
              res.data.data.name,
              res.data.data.content,
              res.data.data.imgUrl,
              // 关闭窗体
              function () {
                // 关闭窗体
                self.map.clearInfoWindow();
                that.$bus.$emit('bus-event', '')
              }
            )
          );
          //   打开窗体
          this.infoWindow.open(this.map, e.target.getPosition());
        }
      });
      let self = this;
    },
    handlePolygon(resulta) {
      this.polygons = [];
      let bounds = resulta.districtList[0].boundaries;
      if (bounds) {
        for (let i = 0, l = bounds.length; i < l; i++) {
          //生成行政区划polygon
          // AMapLoader.load().then((AMap)=>{
          let polygon = new AMap.Polygon({
            map: this.map, // 指定地图对象
            strokeWeight: 1, // 轮廓线宽度
            path: bounds[i], //轮廓线的节点坐标数组
            fillOpacity: 0.15, //透明度
            // fillColor: '',     //填充颜色
            strokeColor: "#4AC3D8", //线条颜色,
          });
          polygon.on("click", (e) => {
            console.log(e);
          });
          // })
        }
      }
    },
    createInfoWindow(title, content, url, callback) {
      var info = document.createElement("div");
      info.className = "custom-info input-card content-window-card";
      //可以通过下面的方式修改自定义窗体的宽高
      info.style.width = "22vw";
      // info.style.height = "30vw";
      // info.style.overflow = "hidden";
      // 定义顶部标题
      var top = document.createElement("div");
      var closeX = document.createElement("img");
      top.className = "info-top";
      top.style.backgroundColor = "rgba(10,133,214,0.4)";
      top.style.position = "relative";
      top.style.color = "#fff";
      closeX.style.position = "absolute";
      closeX.style.right = "0.5vw";
      closeX.style.top = "0.2vw";
      closeX.style.cursor = "pointer";
      closeX.src = require("@/assets/close.png");
      closeX.onclick = callback;
      info.onclick = callback;
      top.innerHTML = title;
      top.appendChild(closeX);
      info.appendChild(top);

      // 定义中部内容
      var middle = document.createElement("div");
      var txt = document.createElement("div");
      var imgUrl = document.createElement("img");
      middle.className = "info-middle";
      txt.className = "info-txt";
      imgUrl.className = "info-imgUrl";
      middle.style.backgroundColor = "rgba(10,133,214,0.4)";
      middle.style.paddingTop = '0.2vw'
      imgUrl.src = url;
      txt.innerHTML = content;
      txt.style.padding = '0.2vw'
      txt.style.fontSize = "0.6vw";
      txt.style.textIndent = "1.2vw";
      txt.style.lineHeight = "1.2vw";
      txt.style.color = "#fff";
      txt.style.textAlign = 'left';
      // txt.style.overflow = "scroll";
      // txt.style.whiteSpace = "nowrap";
      // txt.style.textOverflow = "ellipsis";
      imgUrl.style.width = "100%";
      info.appendChild(middle);
      middle.appendChild(imgUrl);
      middle.appendChild(txt);
      return info;
    },
  },
};
</script>

<style scoped lang="scss">
.maps {
  width: 100%;
  height: 100%;

  #gao-de-map {
    width: 100%;
    height: 100%;
  }
}
</style>