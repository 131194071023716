<template>
  <div class="container">
    <!-- <div class="top">
      <top-bar :current="3"></top-bar>
    </div> -->
    <div class="fixbanner">
      <el-row type="flex" align="center" justify="space-between" :gutters="10">
        <el-col :span="8">
          <div style="height:90vh;overflow:scroll">
            <div class="tourism_data" style="margin-top: 0">
              <div class="left_titles">一镇一屏概况</div>
              <div class="tourism_content">
                <div class="tourism-row">
                  <div class="tourism-col" 
                  @click="openDialog('一镇一屏概况-详情','视频直播：'+oneTownScreen[2].dictValue)">
                    <img src="@/assets/townScreen/icon1.png" />
                    <div>
                      <div class="littel_type">视频直播</div>
                      <div class="data" v-if="oneTownScreen.length">
                        {{ oneTownScreen[2].dictValue
                        }}<span class="tips"> (场)</span>
                      </div>
                    </div>
                  </div>
                  <div class="tourism-col"
                  @click="openDialog('一镇一屏概况-详情','生活短视频：'+oneTownScreen[3].dictValue)"
                  >
                    <img src="@/assets/townScreen/icon2.png" />
                    <div>
                      <div class="littel_type">生活短视频</div>
                      <div class="data" v-if="oneTownScreen.length">
                        {{ oneTownScreen[3].dictValue
                        }}<span class="tips"> (条)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tourism-row">
                  <div class="tourism-col"
                  @click="openDialog('一镇一屏概况-详情','雪亮工程监控接入数：'+oneTownScreen[4].dictValue)"
                  >
                    <img src="@/assets/townScreen/icon3.png" />
                    <div>
                      <div class="littel_type">雪亮工程监控接入数</div>
                      <div class="data" v-if="oneTownScreen.length">
                        {{ oneTownScreen[4].dictValue
                        }}<span class="tips"> (个)</span>
                      </div>
                    </div>
                  </div>
                  <div class="tourism-col"
                  @click="openDialog('一镇一屏概况-详情','入户广电管家数：'+oneTownScreen[5].dictValue)"
                  >
                    <img src="@/assets/townScreen/icon4.png" />
                    <div>
                      <div class="littel_type">入户广电管家数</div>
                      <div class="data" v-if="oneTownScreen.length">
                        {{ oneTownScreen[5].dictValue }}<span class="tips"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tourism_data person_data" @click="richDw('点位数量-详情',dwTotal)">
              <div class="left_titles">点位数量</div>
              <div style="height:2vw"></div>
              <div class="dang_circle">
                <img src="@/assets/townScreen/dang_circle.png" />
                <div class="title">个数</div>
                <div class="nums">{{dwTotal}}</div>
              </div>
            </div>

            <div class="tourism_data person_data">
              <div class="left_titles">政策新动态数量</div>
              <div id="puplicy_echarts"></div>
            </div>
            <div class="tourism_data person_data">
              <div class="left_titles">三务公开数量趋势</div>
              <div id="three_data_echarts"></div>
            </div>
            <div class="tourism_data person_data">
              <div class="left_titles">热点跟踪</div>
              <div style="height:2vw"></div>
              <!-- <ul class="uls">
                <li v-for="(item,index) in list" :key="index" @click="openDialog('热点跟踪-详情',item.content,'')">
                  {{item.title}}
                </li>
              </ul> -->
              <div id="redianEcharts"></div>
            </div>
            <div class="tourism_data person_data">
              <div class="left_titles">热点事件跟踪</div>
               <div style="height:2vw"></div>
              <div class="trtables">
              <th>
                <td>标题</td>
                <td>事件内容</td>
              </th>
              <tr v-for="(item,index) in eventlist" :key="index" @click="openDialog('热点事件跟踪-详情',item.content,'')">
                  <td>{{item.title}}</td>
                  <td style="width:70%">{{item.content}}</td>
              </tr>
            </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8"> </el-col>
        <el-col :span="8">
          <div style="height:90vh;overflow:scroll">
            <div style="pointer-events: auto">
              <div style="display: flex; justify-content: flex-end">
                <div class="persondataNum" @click="openACtive('智慧党建动态总数-详情','动态总数 '+activeTotal)">
                  <div class="right_tiles">智慧党建动态总数</div>
                  <div class="dang_circle">
                    <img src="@/assets/townScreen/dang_circle.png" />
                    <div class="title">动态总数</div>
                    <div class="nums">{{ activeTotal }}</div>
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: flex-end">
                <div class="persondataNum" 
                @click="zxfxDailog('资讯分类')">
                  <div class="right_tiles">资讯分类</div>
                  <div id="echarts3zx"></div>
                </div>
              </div>
              <div style="display: flex; justify-content: flex-end">
                <div class="persondataNum">
                  <div class="right_tiles">信息统计</div>
                  <div id="echarts4zx"></div>
                </div>
              </div>

              <div style="display: flex; justify-content: flex-end">
                <div class="persondataNum">
                  <div class="right_tiles">智慧党建动态数趋势</div>
                  <div id="dang_data_chart"></div>
                </div>
              </div>
              <div style="display: flex; justify-content: flex-end">
                <div class="blackform">
                  <div class="right_tiles">智慧党建最新动态</div>
                  <div class="table_list">
                    <div
                      class="lists"
                      v-for="(item, index) in NewDangList"
                      :key="index"
                      @click="openDialog('智慧党建最新动态-详情',item.content,'')"
                    >
                      <div class="title">
                        <span class="point"></span>
                        <span class="name">{{ item.title }}</span>
                      </div>
                      <div class="tips">{{ item.content }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="MAPS">
      <gaode-map></gaode-map>
    </div> -->
    <com-dialog :dialogVisible="dialogVisible" :contentInfo="dialogContent" :richText="richText"
    :title="dialogtitle" @cancle="dialogVisible = false"></com-dialog>
  </div>
</template>

<script>
// import topBar from '@/components/topBar/index.vue'
// import gaodeMap from '@/components/gaodeMap/index.vue'
import { staticFn } from "@/api/tourism/api";
import comDialogMinx from './minixs'
import {
  publicyfn,
  threeDatafn,
  dangjianTotal,
  dangjianTrend,
  Newdangjian,
} from "@/api/townScreen/api";
import {getINFO} from '@/api/common'
import {getFutureDates} from '@/utils/comonfn'
let echarts3zx,echarts4zx,redianEcharts
export default {
  name: "townScreen",
  // components:{
  //   topBar,
  //   gaodeMap
  // },
  mixins:[comDialogMinx],
  data() {
    return {
      richText:'',
      oneTownScreen: [],
      blacktableData: [],
      webcontruct: [],
      today_dataList: [],
      carList: [],
      personList: [],
      trafficData: [],
      activeTotal: [],
      NewDangList: [],
      title:"",
      contents:'',
      list:[
        {
          title:'用“治理精度”绘出“幸福微笑线”',
          content:`中新网四川新闻1月23日电(吴平华 胡禹)“推进国家治理体系和治理能力现代化，
          必须抓好城市治理体系和治理能力现代化。”城市善治之道，需打磨好基层党建“绣花针”，
          下足精细化治理 “绣花功”，找准群众需求“落针点”。`
        },
        {
          title:'各地多措并举促民生项目落地落实',
          content:`　城东，在建的省第一中医医院建设项目
          主体迎来封顶；城北，五块石中学正在加快建设，
          教育扩优提质民生项目正在加紧推进……`
        },
        {
          title:'坚定发展信心 促进民营经济壮大',
          content:`近日，成都简阳市召开全市民营经济和中小企业发展领导小组第二次全体会议暨民营企业家座谈会，
          传递出简阳市委、市政府毫不动摇鼓励、支持、引导民营经济健康发展的鲜明态度。`
        }
      ],
      eventlist:[
        // {
        //   title:'简阳市总工会举办“书香少年”第五期读书活动',
        //   content:`为继续深入推进“书香简阳”建设，打造工会“书香职工”品牌，营造崇尚读书的职工家
        //   庭氛围，丰富职工子女寒假生活，1月20日，简阳市总工会在职工驿站举办“书香少年”第五期读书活动。`
        // },
        // {
        //   title:'总投资289亿元 简阳举行重大产业化项目集中签约',
        //   content:`中新网四川新闻12月29日电(吴平华 曹礼芹 谷航)同心同行，共创共赢。28日，2023年成都简阳
        //   市结对联动招商引智促产业发展大会暨重大产业化项目集中签约仪式在简阳市文化体育中心(东来印象)举行。`
        // },
      ],
      dwTotal:0,
      zxData:{},
      rdgzData:{}
    };
  },
  mounted() {
    this.getstaticList("overview_one_town_and_one_screen");
    this.$nextTick(() => {
      this.InitPublicyData();
      this.InitThreeData();
      this.InitDangData();
    })
    window.addEventListener("resize", function () {
      echarts3zx.resize()
      echarts4zx.resize()
      redianEcharts.resize()
    });
    this.getALLData()
    // window.onresize = () =>
    //   (() => {
    //     this.InitPublicyData();
    //     this.InitThreeData();
    //     this.InitDangData();
    //   })();
  },
  created() {
    this.InitDangTotal();
    this.getDangList();
  },
  methods: {
    zxfxDailog(title){
      let richText = `
        <div>法律：${this.zxData.fl}</div>
        <div>经济：${this.zxData.jj}</div>
        <div>政治：${this.zxData.zz}</div>
        <div>科技：${this.zxData.kj}</div>
      `
      this.openDialog(title,'',richText)
    },
    openACtive(title,content){
      this.openDialog(title,content)
    },
    richDw(title,dwTotal){
      let richText = ''
      for(let i = 1;i<=dwTotal;i++){
        richText += `<div>点位${i} </div>`
      }
      this.openDialog(title,'',richText)
    },
    getALLData(){
      Promise.all([
        getINFO('/system/dwsl/one'),//点位数量
        getINFO('/system/zxfl/one'),//资讯分类
        getINFO('/system/xxtj/one'),//信息统计
        getINFO('/system/rdsjgz/rdgz'),// 热点跟踪
        getINFO('/system/rdsjgz/list'),//热点事件跟踪
      ]).then(res => {
          this.dwTotal = res[0].data.data
          this.zxEcharts(res[1].data.data)
          this.zxData = res[1].data.data

          this.xxecharts4zx(res[2].data.data)
          this.getredEcharts(res[3].data.data)
          this.eventlist = res[4].data.rows
      })
    },
    getredEcharts(datas) {
      let option = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(52, 57, 67, 0.9)",
            borderColor: "rgba(52, 57, 67)", //设置边框颜色
            axisPointer: {
              type: "shadow",
            },
            textStyle: {
              color: "#fff",
              fontSize: 14,
            },
          },
          grid: {
            top: 60,
            left: 40,
            right: 20,
            bottom: 30,
          },
          xAxis: {
            type: "category",
            data: getFutureDates(),
            color: "#64e7ff",

            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: 14,
              },
            },
          },
          yAxis: {
            name: "单位",
            nameTextStyle: {
              color: "#fff",
              fontSize: 14,
            },
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: 14,
              },
              formatter: function (value) {
                var txt = [];
                // if (value >= 1000) {
                //   txt.push(value / 10000 + "万");
                // } else {
                  
                // }
                txt.push(value);
                return txt;
              },
            },
          },
          series: [
            {
              itemStyle: {
                color: "#64e7ff",
              },
              label: {
                normal: {
                  show: true, //是否显示
                  position: "top", //文字位置
                  formatter: "{c}", //c后面加单位
                  color: "#ffffff",
                  fontSize: 15,
                },
              },
              barWidth: 20,
              data:datas.map(item => item.num),
              type: "bar",
            },
          ],
      }
      redianEcharts = this.$echarts.init(
        document.getElementById("redianEcharts")
      );
      redianEcharts.setOption(option);
    },
    xxecharts4zx(datas){
      let tootipTimer1 = "",
      option = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(52, 57, 67, 0.9)",
            borderColor: "rgba(52, 57, 67)", //设置边框颜色
            axisPointer: {
                type: "shadow",
            },
            textStyle: {
                color: "#fff",
                fontSize: 15,
            },
          },
          grid: {
            top: 60,
            left: 40,
            right: 20,
            bottom: 30,
          },
          xAxis: {
            type: "category",
            data: ["政策", "党建", "活动", "建设", "改善", "维护"],
            color: "#64e7ff",
            axisLabel: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 14,
                },
            },
          },
          yAxis: {
            name: " ",
            nameTextStyle: {
                color: "#fff",
                fontSize: 14,
            },
            type: "value",
            axisLabel: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 12,
                },
                formatter: function (value) {
                  var txt = [];
                  if (value >= 1000) {
                      txt.push(value / 10000 + " ");
                  } else {
                      txt.push(value);
                  }
                  return txt;
                },
            },
          },
          series: [
            {
                itemStyle: {
                  color: "#64e7ff",
                },
                label: {
                  normal: {
                      show: true, //是否显示
                      position: "top", //文字位置
                      formatter: "{c}", //c后面加单位
                      color: "#ffffff",
                      fontSize: 12,
                  },
                },
                barWidth: 10,
                data: [datas.zc, datas.dj, datas.hd, datas.js, datas.gs, datas.wh],
                type: "bar",
            },
          ],
      };
      // echarts4zx
      echarts4zx = this.$echarts.init(document.getElementById("echarts4zx"))
      echarts4zx.setOption(option);
    },
    // echarts3zx
    zxEcharts(datas){
      let result = [
        {name:'法律',value:datas.fl},
        {name:'经济',value:datas.jj},
        {name:'政治',value:datas.zz},
        {name:'科技',value:datas.kj},
      ]
      let option = {
        tooltip: {
          show: true,
          trigger: 'item',
          padding: [8, 8],
          backgroundColor: 'rgba(12, 51, 115,0.8)',
          borderColor: 'rgba(3, 11, 44, 0.5)',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)'
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: '5%',
          right: '16%',
          top: '15%',
          bottom: '8%',
        },
        xAxis: [
          {
            splitLine: {
              show: false,
            },
            type: 'value',
            show: false,
          },
        ],
        yAxis: [
          {
            type: 'category',
                inverse: true,
                offset: 70,
                position: 'left',
                axisLabel: {
                  show: true,
                  align: 'left',
                  textStyle: {
                    color: '#fff',
                    fontSize: 14,
                  },
                  formatter: function (value, index) {
                    var num = '';
                    var str = '';
                    num = index + 1;
                    str = '{name|' + result[index].name + '}';
                    return str;
                  },
                  rich: {
                    name: { color: '#fff', fontSize: 14, padding: [40, 130, 2, 80] },
                  },
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
            data: result.map((item)=>item.name),
          
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              align: 'left',
              textStyle: {
                color: '#fff',
                fontSize: 12,
              },
              formatter: function (value, index) {
                    var num = '';
                    var str = '';
                    num = index + 1;
                    str = '{name|' + result[index].value + '}';
                    return str;
                  },
                  rich: {
                    name: { color: '#fff', fontSize: 14, padding: [40, 0, 2, 0] },
                  },
            },
            data: result.map((item)=>item.value),
          },
        ],
        series: [
          {
            name: '率',
            type: 'bar',
            barWidth: 10, // 柱子宽度
            MaxSize: 0,
            showBackground: true,
            backgroundStyle: {
              color:'rgba(79, 98, 114, 0.6)',
            },
            label: {
              show: false,
            },
            data: result.map((item,index)=>{
              return{
                name:item.name,
                value:item.value,
                itemStyle: {
              color: {
                x: 1,
                y: 0,
                x2: 0,
                y2: 0,
                colorStops: [{
                  offset: 0,
                  color: '#1CEBA0' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#11CDEB' // 100% 处的颜色
                }],
              },
              borderRadius: 0,
            },
              }
            }),
          },
          {
            name: "外圆",
            type: "scatter",
            emphasis: {
              scale: false,
            },
            symbol: "rect",
            symbolSize: [5,16],
            itemStyle: {
              color: '#FFF',
              shadowColor: "rgba(255, 255, 255, 0.5)",
              shadowBlur: 5,
              borderWidth: 1,
              opacity: 1,
            },
            z: 2,
            data: result,
            animationDelay: 500,
          },
        ],
      };
      echarts3zx = this.$echarts.init(document.getElementById("echarts3zx"))
      echarts3zx.setOption(option);
    },
    // 政策新动态
    async InitPublicyData() {
      let dataAxis = [];
      let data = [];
      let yMax = 1000;
      let dataShadow = [];
      const res = await publicyfn();
      if (res.data.code == 200) {
        res.data.data.map((item) => {
          dataAxis.push(item.month);
          data.push(item.number);
        });
      }
      let myEcharts = this.$echarts.init(
        document.getElementById("puplicy_echarts")
      );
      for (let i = 0; i < data.length; i++) {
        dataShadow.push(yMax);
      }
      let option = {
        grid: {
          left: "10%",
          right: "10%",
          bottom: "0%",
          containLabel: true,
        },
        tooltip: {
          type: "item",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            color: "rgba(222, 235, 255, 1)",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          textStyle: {
            fontSize: 12,
          },
          z: 1,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "rgba(222, 235, 255, 1)",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(110, 118, 158, 1)",
              type: "solid",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: true,
            barMaxWidth: 30,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(66, 170, 242, 0.5)",
                    },
                    {
                      offset: 1,
                      color: "rgba(4, 243, 247, 1)",
                    },
                  ],
                },
                barBorderRadius: [10, 10, 0, 0],
                label: {
                  show: false, //开启显示
                },
              },
            },
            data: data,
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 三务公开数量趋势
    async InitThreeData() {
      let dataAxis = [];
      let data = [];
      const res = await threeDatafn();
      if (res.data.code == 200) {
        res.data.data.map((item) => {
          dataAxis.push(item.month);
          data.push(item.number);
        });
      }
      let myEcharts = this.$echarts.init(
        document.getElementById("three_data_echarts")
      );
      let option = {
        grid: {
          left: "10%",
          right: "10%",
          bottom: "0%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "rgba(255, 171, 63, 1)",
            },
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: 1,
            axisLine: {
              show: false,
            },
            axisLabel: {
              color: "rgba(222, 235, 255, 1)",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },

            data: dataAxis,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            padding: 5,
            // max: 1000,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(110, 118, 158, 1)",
                type: "solid",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: 10,
              textStyle: {
                color: "rgba(222, 235, 255, 1)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            stack: "",
            symbolSize: 5,
            showSymbol: false,
            itemStyle: {
              normal: {
                color: "#38D0FB",
                lineStyle: {
                  color: "rgba(255, 171, 63, 1)",
                  width: 2,
                },
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(255, 171, 63, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(254, 211, 12, 0.2)",
                    },
                  ],
                  false
                ),
              },
            },
            data: data,
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 智慧党建总数
    async InitDangTotal() {
      const res = await dangjianTotal();
      if (res.data.code == 200) {
        this.activeTotal = res.data.data;
      }
    },
    // 智慧党建动态趋势
    async InitDangData() {
      let xLabel = [];
      let goToSchool = [];
      const res = await dangjianTrend();
      if (res.data.code == 200) {
        res.data.data.map((item) => {
          xLabel.push(item.month);
          goToSchool.push(item.number);
        });
      }
      let myEcharts = this.$echarts.init(
        document.getElementById("dang_data_chart")
      );

      let option = {
        grid: {
          top: "20%",
          left: "15%",
          right: "15%",
          bottom: "20%",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          confine: true,
          backgroundColor: "rgba(0,0,0,0.5)",
          borderWidth: 0,
          textStyle: {
            color: "#fff", //设置文字颜色
          },
          formatter: function (params) {
            let html = "";
            html += `${params[0].axisValueLabel} <br> 车流量为：${params[0].value}辆`;
            return html;
          },
        },
        xAxis: [
          {
            // type: 'category',
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#fff",
                padding: 10,
                fontSize: 10,
              },
              rotate: "30",
              formatter: function (data) {
                return data;
              },
            },
            splitLine: {
              show: false,
            },
            data: xLabel,
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: 10,
            },
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                padding: 16,
              },
              formatter: function (value) {
                if (value === 0) {
                  return value;
                }
                return value;
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "line",
            showAllSymbol: true,
            smooth: true,
            symbol: "circle",
            symbolSize: 12,
            lineStyle: {
              normal: {
                width: 3,
                color: "#4EFDFF", // 线条颜色
              },
              type: "solid",
            },
            itemStyle: {
              color: "transparent",
              borderWidth: 2,
              borderColor: "#4EFDFF",
            },
            label: {
              show: true,
            },
            areaStyle: {
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(4, 243, 247,.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(4, 243, 247,0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: goToSchool,
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 智慧党建最新
    async getDangList() {
      const res = await Newdangjian(1, 20);
      if (res.data.code == 200) {
        this.NewDangList = res.data.rows;
      }
    },
    // 公共接口
    async getstaticList(type) {
      const res = await staticFn(type);
      if (res.data.code == 200) {
        if (type == "overview_one_town_and_one_screen") {
          this.oneTownScreen = res.data.data;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

@keyframes fadeinR {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeinL {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }

  .fixbanner {
    z-index: 888;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.8) 55%);
    @include pt(80);
    // @include pl(20);
    // @include pr(20)
  }

  .tourism_data {
    pointer-events: auto;
    color: #fff;
    @include wd(486);
    @include height(296);
    @include mt(65);
    @include backimage("@/assets/townScreen/left_bg.png");
    position: relative;
    animation: fadeinL 2s;

    .tourism_content {
      position: absolute;
      @include top(25);
      left: 0;
      right: 0;
      bottom: 0;
      @include pl(15);
      @include pr(15);
      @include pt(20);

      .tourism-row {
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include mt(15);
      }

      .tourism-col {
        width: 50%;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include mb(55);

        img {
          @include wd(74);
          @include height(49);
          @include ml(10);
        }

        .tips {
          color: #aed4d4;
          @include font(12);
        }
      }

      .data {
        @include font(22);
        color: #04f5f7;
        font-weight: bold;
      }

      .dot {
        display: inline-block;
        @include wd(6);
        @include height(6);
        border-radius: 50%;
        background-color: #ff9141;
        box-shadow: 0.2vw 0.2vw 0.5vw 0 rgba(255, 145, 65, 0.8);
        @include mr(10);
        @include font(14);
      }

      .dot1 {
        background: #2fa4ff;
        box-shadow: 0.2vw 0.2vw 0.5vw 0 rgba(47, 164, 255, 0.8);
      }
    }
  }
}

.MAPS {
  width: 100vw;
  height: 60vw;
}

.left_titles {
  position: absolute;
  @include top(10);
  left: 10%;
  color: #04f3f7;
  @include font(18);
  font-weight: bold;
  font-style: italic;
}

.right_tiles {
  position: absolute;
  @include top(10);
  right: 10%;
  color: #04f3f7;
  @include font(18);
  font-weight: bold;
  font-style: italic;
}

.littel_type {
  @include font(14);
  @include lh(30);
  @include wd(96);
  @include height(40);
  @include ml(10);
  @include pl(5);
  white-space: nowrap;
  background-image: url("@/assets/smartTour/title_bg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}

#puplicy_echarts {
  width: 100%;
  height: 85%;
  pointer-events: auto;
}

#three_data_echarts {
  width: 100%;
  height: 85%;
  pointer-events: auto;
}

#dang_data_chart {
  width: 100%;
  height: 85%;
}

.table_list {
  @include ml(44);
  overflow-y: scroll;
  @include height(380);
  .lists {
    width: 90%;
    text-align: left;
    @include pt(30);
    @include pb(30);
    border-bottom: 0.1vw solid rgba(78, 228, 255, 1);
    cursor: pointer;
    .title {
      display: flex;
      align-items: center;

      .point {
        display: inline-block;
        @include wd(6);
        @include height(6);
        background: rgba(255, 171, 63, 1);
        box-shadow: 0px 0.2vw 0.4vw 0px #ffab3f;
        border-radius: 50%;
      }

      .name {
        flex: 1;
        @include ml(10);
        color: rgba(255, 255, 255, 1);
        @include font(18);
      }
    }

    .tips {
      color: rgba(142, 198, 216, 1);
      @include font(14);
      @include mt(22);
      width: inherit;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}

.persondataNum {
  position: relative;
  @include wd(489);
  @include backimage("@/assets/townScreen/right_bg.png");
  @include height(222);
  @include pt(50);
  @include pl(20);
  @include mt(10);
  animation: fadeinR 2s;
  cursor: pointer;
}

.blackform {
  position: relative;
  @include wd(489);
  @include backimage("@/assets/smartTour/right_bg1.png");
  @include height(410);
  @include pt(50);
  @include pl(20);
  @include mt(10);
  animation: fadeinR 2s;
}

.dang_circle {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    position: absolute;
    @include wd(184);
    @include height(154);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }

  .title {
    color: #fff;
    @include font(18);
    position: absolute;
    top: 22%;
    left: 23%;
  }

  .nums {
    @include wd(100);
    @include height(50);
    @include font(30);
    font-weight: bold;
    color: #fdbf46;
    background: linear-gradient(0deg, #faff67 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-35%, -45%);
  }
}

::-webkit-scrollbar {
  border-radius: 5px;
  height: 10px;
  background-color: transparent;
  display: none !important;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  display: none !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
  display: none !important;
}
::v-deep .el-dialog {
  background-color: transparent;
  box-shadow: none;
}
.dialogcontent {
  border: 1px solid #04b8bb;
  padding: 20px;
  background-color: rgba(40, 72, 77, 0.3);
  .flexs {
    display: flex;
    justify-content: space-between;
  }
  .title {
    color: #04f3f7;
  }
  img {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}
.dang_circle {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    position: absolute;
    @include wd(184);
    @include height(154);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }

  .title {
    color: #fff;
    @include font(18);
    position: absolute;
    top: 22%;
    left: 23%;
  }

  .nums {
    @include wd(100);
    @include height(50);
    @include font(30);
    font-weight: bold;
    color: #fdbf46;
    background: linear-gradient(0deg, #faff67 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-35%, -45%);
  }
}
#echarts3zx,
#echarts4zx {
  width: 100%;
  height: 80%;
}
.uls {
  color: #01f0ff;
  @include pl(20);
  @include pr(20);
  @include pt(20);
  @include font(14);
  box-sizing: border-box;
  li {
    @include mt(10);
    text-align: left;
    @include ml(20);
  }
}
.trtables {
  height: 90%;
  @include pt(10);
  @include pl(50);
  @include pr(50);
  box-sizing: border-box;
  overflow-y: scroll;
  th,
  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include height(40);
    @include lh(40);
    text-align: center;
  }
  th {
    color: #04f3f7;
    @include font(14);
  }
  tr {
    color: #dffdff;
    @include font(12);
  }
  td {
    width: 25%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
  }
  .iamgs {
    display: inline-block;
    @include wd(24);
    @include height(24);
    @include br(2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
#redianEcharts {
  width: 100%;
  height: 90%;
}
</style>