import service from "@/utils/requestFn";
export function staticFn (type) {
    return service({
        url:`/system/dict/data/type/${type}`,
        method:'get'
    })
}
// 人流量趋势
export function person_dataList (){
    return service({
        url:'/system/peopleflowtrend/week',
        method:'get'
    })
}
// 车流量
export function car_dataList () {
    return service({
        url:'/system/trafficflowtrend/year',
        method:'get'
    })
}
// 黑名单数据
export function blackList (pageNum,pageSize){
    return service({
        url:`/system/blacklist/list?pageNum=${pageNum}&pageSize=${pageSize}`,
        method:'get'
    })
}
//车辆列表
export function carLists (pageNum,pageSize){
    return service({
        url:`/system/trafficflowanalysis/list?pageNum=${pageNum}&pageSize=${pageSize}`,
        method:'get',
    })
}
// 人流量分析
export function personLists(pageNum,pageSize){
    return service({
        url:`/system/peopleflow/list?pageNum=${pageNum}&pageSize=${pageSize}`,
        method:'get',
    })
}