export default {
    data(){
        return{
            dialogVisible:false,
            dialogtitle:'',
            dialogContent:''
        }
    },
    methods: {
        openDialog(title,content,richText){
            this.dialogtitle = title
            this.dialogContent = content
            this.richText = richText
            this.dialogVisible = true
        }
    },
}