
import service from "@/utils/requestFn";
export function eventList(pageNum,pageSize) {
    return service({
        url: `/system/events/list?pageNum=${pageNum}&pageSize=${pageSize}`,
        method:'get'
    })
}
export function yimiao (){
    return service({
      url:'/system/vaccine/half/year',
      method:'get'
    })
}