<template>
  <div>
    <el-dialog
      :visible.sync="openShow"
      append-to-body
      width="50rem"
      :show-close="false"
    >
      <div class="pop-box" v-if="openShow">
        <div slot="title" class="pop-title">
          <img class="icon" src="@/assets/icon (9).png" alt="" />
          <div class="name">{{ title }}</div>
          <img
            class="close"
            @click="$emit('cancle')"
            src="@/assets/icon (10).png"
            alt=""
          />
        </div>
        <div class="pop-content">
            {{ contentInfo }}
            <div v-html="richText" v-if="richText"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    contentInfo: {
      type: String,
      default: "",
    },
    richText: {
      type: String,
      default: "",
    },
  },
  computed: {
    openShow: {
      get() {
        return this.dialogVisible;
      },
      set(newVal) {
        this.$emit("cancle");
      },
    },
  },
};
</script>

<style  scoped lang="scss">
.pop-box {
  width: 50rem;
  background: rgba(6, 29, 46, 0.9);
  border: 1px solid rgba(112, 221, 234, 0.2);
  height: fit-content;
  .pop-content {
    font-size: 0.8vw;
    padding: 1.25rem;
    color: #eff;
    max-height: 85vh;
    overflow-y: scroll;
  }
}

.pop-title {
  height: 2rem;
  background: linear-gradient(
    90deg,
    rgba(36, 184, 225, 0) 0%,
    rgba(36, 184, 225, 0.6) 100%
  );
  border-radius: 0rem 0rem 0rem 0rem;
  pointer-events: all;
  display: flex;
  align-items: center;

  .icon {
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
  }

  .name {
    font-size: 1rem;
    color: #ffffff;
    flex: 1;
  }

  .close {
    width: 1rem;
    height: 1rem;
    margin: 0 1.38rem;
    cursor: pointer;
  }
}

::-webkit-scrollbar {
  border-radius: 5px;
  height: 10px;
  background-color: transparent;
  display: none !important;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  display: none !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
  display: none !important;
}
</style>