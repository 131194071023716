<template>
  <div class="container">
    <!-- <div class="top">
      <top-bar :current="0"></top-bar>
    </div> -->
      <div class="t">
        <div style="height:92vh;overflow:scroll;position: relative;z-index: 1;">
          <div class="fixbanner">
            <div class="t_l">
              <div class="t_l_1">
                <div class="t_l_t">景点旅游数据</div>
                <div class="t_l_v">视屏分屏</div>
                <div class="t_l_c">
                  <div class="t_l_l" @click="openDialog('人流量统计-详情',spot[0])">
                    <el-image
                      :src="require('@/assets/tourism/t_u.png')"
                      fit="contain"
                    ></el-image>
                    <div class="t_l_l_r">
                      <div class="t_l_l_r_t">人流量统计</div>
                      <div class="t_l_l_r_b">{{ spot[0] }}<span>（人）</span></div>
                    </div>
                  </div>
                  <div class="t_l_s">
                    <div class="t_sex"><i></i><span>女性</span>{{ spot[3] }}%</div>
                    <div class="t_sex">
                      <i class="man"></i><span>男性</span>{{ spot[4] }}%
                    </div>
                  </div>
                  <div class="t_l_l" @click="openDialog('感知设备-详情',spot[2]+'（台）')">
                    <el-image
                      :src="require('@/assets/tourism/t_s.png')"
                      fit="contain"
                    ></el-image>
                    <div class="t_l_l_r">
                      <div class="t_l_l_r_t">感知设备</div>
                      <div class="t_l_l_r_b">{{ spot[2] }}<span>（台）</span></div>
                    </div>
                  </div>
                  <div class="t_l_l" @click="openDialog('车流量统计-详情',spot[1]+'（辆）')">
                    <el-image
                      :src="require('@/assets/tourism/t_c.png')"
                      fit="contain"
                    ></el-image>
                    <div class="t_l_l_r">
                      <div class="t_l_l_r_t">车流量统计</div>
                      <div class="t_l_l_r_b">{{ spot[1] }}<span>（辆）</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t_l_1">
                <div class="t_l_t">人流量趋势</div>
                <div class="t_l_h" id="homeEchart"></div>
              </div>
              <div class="t_l_1">
                <div class="t_l_t">车流量趋势</div>
                <div class="t_l_h" id="homeEchart1"></div>
              </div>
              <div class="t_l_1">
                <div class="t_l_t">热点事件</div>
                <div class="trtables">
                  <th style="margin-top:1.1vw">
                    <td>标题</td>
                    <td>事件地点</td>
                    <td>事件内容</td>
                    <td>处理描述</td>
                  </th>
                  <tr v-for="(item,index) in eventlist" :key="index"
                  @click="openRichText('热点事件-详情',item)"
                  >
                      <td>{{item.title}}</td>
                      <td>{{item.place}}</td>
                      <td>{{item.content}}</td>
                      <td>{{item.remark}}</td>
                  </tr>
                </div>
              </div>
            </div>
            <div class="t_l">
              <div class="t_l_3">
                <div class="t_l_t">乡村业务数据汇总</div>
                <div class="t_l_v">
                  <span
                    @click="tlvClick(1)"
                    :class="tlvIndex == 1 ? 't_l_v_a t_l_v_i' : 't_l_v_i'"
                    >上月</span
                  >
                  <span
                    @click="tlvClick(2)"
                    :class="tlvIndex == 2 ? 't_l_v_a t_l_v_i' : 't_l_v_i'"
                    >近半年</span
                  >
                  <span
                    @click="tlvClick(3)"
                    :class="tlvIndex == 3 ? 't_l_v_a t_l_v_i' : 't_l_v_i'"
                    >近一年</span
                  >
                </div>
                <div class="t_l_list">
                  <div class="t_l_i" @click="openDialog('乡村业务数据汇总-详情','事件上报数:'+summary[0].assistanceNumber)">
                    <el-image
                      :src="require('@/assets/tourism/t_l.png')"
                      fit="contain"
                      class="iamges"
                    ></el-image>
                    <span>事件上报数</span>
                    <div class="t_l_b" v-if="summary.length > 0">
                      {{ summary[0].assistanceNumber }}<i>(件)</i>
                    </div>
                  </div>
                  <div class="t_l_i" @click="openDialog('乡村业务数据汇总-详情','意见建议数:'+summary[0].commentsNumber)">
                    <el-image
                      :src="require('@/assets/tourism/t_l.png')"
                      fit="contain"
                      class="iamges"
                    ></el-image>
                    <span>意见建议数</span>
                    <div class="t_l_b" v-if="summary.length > 0">
                      {{ summary[0].commentsNumber }}<i>(条)</i>
                    </div>
                  </div>
                  <div class="t_l_i" @click="openDialog('乡村业务数据汇总-详情','帮扶数:'+summary[0].eventsNumber)">
                    <el-image
                      :src="require('@/assets/tourism/t_l.png')"
                      fit="contain"
                      class="iamges"
                    ></el-image>
                    <span>帮扶数</span>
                    <div class="t_l_b" v-if="summary.length > 0">
                      {{ summary[0].eventsNumber }}<i>(次)</i>
                    </div>
                  </div>
                  <div class="t_l_i" @click="openDialog('乡村业务数据汇总-详情','三务公开数:'+summary[0].publicNumber)">
                    <el-image
                      :src="require('@/assets/tourism/t_l.png')"
                      fit="contain"
                      class="iamges"
                    ></el-image>
                    <span>三务公开数</span>
                    <div class="t_l_b" v-if="summary.length > 0">
                      {{ summary[0].publicNumber }}<i>(次)</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t_l_4">
                <div class="t_l_t">近半年乡村人员流动登记信息</div>
                <div class="homeEchart3" id="homeEchart3"></div>
              </div>
              
              <div class="t_l_4">
                <div class="t_l_t">热门资讯</div>
                <ul class="uls">
                  <li v-for="(item,index) in newList" :key="index" @click="openDialog('热门资讯-详情',item.content)">
                    {{item.title}}
                  </li>
                </ul>
              </div>
              <div class="t_l_5">
                <div class="t_l_t">乡村指标数据汇总</div>
                <div class="t_l_list">
                  <div class="t_l_i" v-for="(i, index) in indexList" :key="index"
                  @click="openDialog('乡村指标数据汇总-详情',i.dictLabel+':'+i.dictValue)"
                  >
                    <span :style="{ color: colorList[index] }">{{
                      i.dictValue
                    }}</span>
                    <div class="t_l_b">
                      {{ i.dictLabel }}({{ indexUnit[index] }})
                    </div>
                  </div>
                </div>
              </div>
            </div>          
          </div>
        <div class="t_l t_flex">
          <div class="t_l_1">
            <div class="t_l_t">黑名单预警</div>
            <div class="t_l_over">
              <table class="t_l_table">
                <tr>
                  <th class="t_t_th">地点</th>
                  <th class="t_t_th">特征</th>
                  <th class="t_t_th">抓拍图</th>
                  <th class="t_t_th">地库图</th>
                </tr>
                <tr v-for="(i, index) in black" :key="index" 
                 @click="openRichText2('黑名单预警-详情',i)">
                  <td class="t_t_td">{{ i.place }}</td>
                  <td class="t_t_td">{{ i.features }}</td>
                  <td class="t_t_td">
                    <el-image
                      class="t_t_image"
                      :src="i.snapshotImg"
                      fit="contain"
                    ></el-image>
                  </td>
                  <td class="t_t_td">
                    <el-image
                      class="t_t_image"
                      :src="i.basementImg"
                      fit="contain"
                    ></el-image>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="t_l_1 t_l_2">
            <div class="t_l_t">一镇一屏概况</div>
            <div class="t_l_c">
              <div
                class="t_l_l t_l_z"
                v-for="(i, index) in townList"
                :key="index"
                @click="openDialog('一镇一屏概况-详情',i.name+':'+screen[index].dictValue)"
              >
                <el-image :src="i.url" fit="contain"></el-image>
                <div class="t_l_l_r">
                  <div class="t_l_l_r_t">
                    {{ i.name }}
                  </div>
                  <div class="t_l_l_r_b" v-if="screen.length > 0">
                    {{ screen[index].dictValue }}
                    <span v-if="i.unit">（{{ i.unit }}）</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t_l_1 t_l_2">
            <div class="t_l_t">人人通</div>
            <div class="t_l_p_r">
              <div class="t_l_p_r_l">
                <div class="t_l_p">
                  <i class="t_l_p_l"></i><span>人人通用户情况</span>
                </div>
                <div class="t_t_tol">
                  <el-image
                    :src="require('@/assets/tourism/t_l.png')"
                    fit="contain"
                  ></el-image>
                  <span class="t_t_total">用户总数</span>
                  <span class="t_t_num ml0">{{ Data.totalNum }}</span
                  >（人）
                </div>
                <div class="t_t_tol">
                  <el-image
                    :src="require('@/assets/tourism/t_l.png')"
                    fit="contain"
                  ></el-image>
                  <span class="t_t_total">今日新增用户数</span>
                  <span class="t_t_num">{{ Data.todayNum }}</span
                  >（辆）
                </div>
              </div>
              <div
                class="t_l_p_r_l"
                style="width: 70%; height: 80%; margin-left: 5%"
              >
                <div class="t_l_p">
                  <i class="t_l_p_l"></i><span>用户最近一周新增趋势</span>
                </div>
                <div id="homeEchart2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
   </div>
   <com-dialog :dialogVisible="dialogVisible" :contentInfo="dialogContent" :richText="richText"
    :title="dialogtitle" @cancle="dialogVisible = false"></com-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { staticFn } from "@/api/tourism/api";
import { eventList } from "@/api/rural/api";
import comDialogMinx from "./minixs";
import {getINFO} from '@/api/common'
import {
  peopleflowtrend,
  businessSummary,
  tongData,
  tongWeek,
  blacklist,
  halfYear,
  trafficflowtrend,
} from "@/api/index";
export default {
  name: "TourismIndex",
  mixins: [comDialogMinx],
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(17,95,182,0.5)",
          textStyle: {
            color: "#fff",
          },
          triggerOn: "mousemove",
          showContent: true,
        },
        title: {
          left: 26,
          top: 26,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 15,
            fontWeight: 50000,
            fontFamily: "PingFang SC",
          },
        },
        grid: {
          top: "18%",
          left: "1%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            // end: dataList.length > 15 ? 35 : 100,
          },
        ],
        xAxis: {
          axisLine: {
            lineStyle: {
              color: "rgba(59, 80, 124, 1)",
            },
          },
          axisTick: {
            show: false,
          },
          //轴线上的字
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.8)",
              fontSize: "14",
            },
          },
          data: ["12月", "1月", "2月", "3月", "4月", "5月", "6月"],
        },
        yAxis: [
          {
            name: "单位(人)",
            nameTextStyle: {
              color: "rgba(255,255,255,0.8)",
            },
            type: "value",
            splitNumber: 4,
            axisTick: {
              show: false,
            },
            //轴线上的字
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: "14",
                color: "rgba(255,255,255,0.8)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#397cbc",
              },
            },
            //网格线
            splitLine: {
              lineStyle: {
                color: "#11366e",
              },
            },
          },
        ],
        series: [
          {
            name: "人流量为:",
            type: "line",
            smooth: true, //是否平滑曲线显示
            showSymbol: false,
            markPoint: {
              data: [
                {
                  name: "周最高",
                  value: 320,
                  xAxis: 4,
                  yAxis: 320,
                },
              ],
            },
            itemStyle: {
              color: "#F3A22D",
              borderColor: "#F3A22D",
              borderWidth: 1,
            },
            lineStyle: {
              normal: {
                width: 2,
                color: {
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "#F3A22D", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#F3A22D", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
                shadowColor: "#F3A22D",
                shadowBlur: 30,
                shadowOffsetY: 5,
              },
            },
            areaStyle: {
              //区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(243,162,45, 0.6)",
                    },
                    {
                      offset: 0.6,
                      color: "rgba(243,162,45, 0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(243,162,45, 0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(243,162,45, 0.1)",
                shadowBlur: 6,
              },
            },
            data: [
              {
                name: "12月",
                value: "7",
              },
              {
                name: "1月",
                value: "6",
              },
              {
                name: "2月",
                value: "6.5",
              },
              {
                name: "3月",
                value: "5.3",
              },
              {
                name: "4月",
                value: "6",
              },
              {
                name: "5月",
                value: "5.2",
              },
              {
                name: "6月",
                value: "5.8",
              },
            ],
          },
        ],
      },
      option1: {
        tooltip: {
          textStyle: {
            color: "#000",
          },
          padding: [10, 10],
          trigger: "axis",
          backgroundColor: "#fff",
          borderColor: "rgba(112, 119, 242, 0.8)",
          borderWidth: 1,
        },
        grid: {
          top: "18%",
          left: "1%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        toolbox: {
          show: false,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: "rgba(199, 214, 240, 1)",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            //坐标轴刻度标签的相关设置
            textStyle: {
              color: "#fff",
              padding: 12,
              fontSize: 10,
            },
            rotate: "30",
            formatter: function (data) {
              return data;
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLabel: {
              textStyle: {
                color: "rgba(199, 214, 240, 1)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(59, 80, 124, 1)",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(45, 60, 92, 1)",
              },
            },
          },
        ],

        series: [
          {
            // name: '近7日新登记数量',
            type: "line",
            data: [],
            symbolSize: 10,
            symbol: "circle",
            smooth: false,
            yAxisIndex: 0,
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(4, 243, 247, 0.5)",
                },
                {
                  offset: 1,
                  color: "rgba(4, 243, 247, 0.6)",
                },
              ]),
              shadowColor: "rgba(4, 243, 247, 0.4)",
              shadowBlur: 10,
              shadowOffsetY: 10,
            },
            itemStyle: {
              normal: {
                color: "rgba(255, 255, 255, 0.4)",
                borderColor: "#fff",
                borderWidth: 1,
                shadowColor: "rgba(4, 243, 247, 0.7)",
                shadowBlur: 3,
              },
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(78, 170, 255, 0.2)",
                    },
                    {
                      offset: 0.5,
                      color: "rgba(4, 243, 247, 0.1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(4, 243, 247, 0)",
                    },
                  ],
                  false
                ),
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
        ],
      },
      option2: {
        grid: {
          top: "18%",
          left: "1%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          backgroundColor: "rgba(255, 132, 90, 0.3)",
          borderColor: "#FF845A",
          extraCssText: "box-shadow: 2px 2px 4px 0px rgba(255, 132, 90, 0.3);",
          textStyle: {
            fontSize: 16,
            color: "#fff",
            fontFamily: "PangMenZhengDao",
          },
          formatter: function (params) {
            var relVal = params[0].name;
            for (var i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                ": " +
                params[i].value +
                " 条";
            }
            return relVal;
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#FF845A",
            },
          },
        },
        legend: {
          orient: "horizontal",
          right: "3%",
          itemWidth: 20,
          itemHeight: 4,
          icon: "react",
          itemGap: 17,
          align: "right",
          textStyle: {
            color: "#AEC9FF",
            fontSize: 16,
            fontFamily: "AlibabaPuHuiTi-Medium",
          },
        },
        xAxis: {
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          boundaryGap: true,
          axisLine: {
            show: true,
            symbol: ["none", "rect"],
            symbolSize: [6, 12],
            lineStyle: {
              width: 2,
              color: "#537DAA",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          // name: "(万条)",
          nameTextStyle: {
            color: "#AEC9FF",
            fontWeight: 400,
            fontSize: 14,
          },
          axisLabel: {
            color: "#AEC9FF",
          },
          axisLine: {
            show: true,
            symbol: ["none", "rect"],
            symbolSize: [6, 12],
            lineStyle: {
              width: 2,
              color: "#537DAA",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(83, 125, 170, 0.2)",
            },
          },
        },
        series: [
          {
            // name: "归集",
            data: [60, 80, 100, 70, 65, 69, 80, 100, 70, 65, 69, 66],
            type: "line",
            symbol: "circle",
            symbolSize: 3,
            itemStyle: {
              color: "#FF845A",
              shadowColor: "#FF845A",
              shadowBlur: 10,
              borderColor: "#FF845A",
              borderWidth: 3,
              lineStyle: {
                color: "#FF845A",
              },
            },
          },
          {
            // name: "共享",
            data: [70, 50, 80, 60, 62, 54, 34, 66, 60, 55, 50, 45],
            type: "line",
            symbol: "circle",
            symbolSize: 3,
            itemStyle: {
              color: "#5A94C5",
              shadowColor: "#5A94C5",
              shadowBlur: 10,
              borderColor: "#5A94C5",
              borderWidth: 3,
              lineStyle: {
                color: "#5A94C5",
              },
            },
          },
        ],
      },
      option3: {
        grid: {
          top: "18%",
          left: "1%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          backgroundColor: "rgba(255, 132, 90, 0.3)",
          borderColor: "#FF845A",
          extraCssText: "box-shadow: 2px 2px 4px 0px rgba(255, 132, 90, 0.3);",
          textStyle: {
            fontSize: 16,
            color: "#fff",
            fontFamily: "PangMenZhengDao",
          },
          formatter: function (params) {
            var relVal = params[0].name;
            for (var i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                ": " +
                params[i].value +
                " 条";
            }
            return relVal;
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#FF845A",
            },
          },
        },
        legend: {
          orient: "horizontal",
          left: "3%",
          align: "left",
          textStyle: {
            color: "#DEEBFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular",
          },
        },
        xAxis: {
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          boundaryGap: true,
          axisLine: {
            show: true,
            symbol: ["none", "rect"],
            symbolSize: [6, 12],
            lineStyle: {
              width: 2,
              color: "#537DAA",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          // name: "(万条)",
          nameTextStyle: {
            color: "#AEC9FF",
            fontWeight: 400,
            fontSize: 14,
          },
          axisLabel: {
            color: "#AEC9FF",
          },
          axisLine: {
            show: true,
            symbol: ["none", "rect"],
            symbolSize: [6, 12],
            lineStyle: {
              width: 2,
              color: "#537DAA",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(83, 125, 170, 0.2)",
            },
          },
        },
        series: [
          {
            name: "返乡登记数",
            data: [60, 80, 100, 70, 65, 69, 80, 100, 70, 65, 69, 66],
            type: "line",
            symbol: "circle",
            symbolSize: 3,
            itemStyle: {
              color: "#5EB8FF",
              shadowColor: "#5EB8FF",
              shadowBlur: 10,
              borderColor: "#5EB8FF",
              borderWidth: 3,
              lineStyle: {
                color: "#5EB8FF",
              },
            },
          },
          {
            name: "外出登记数",
            data: [70, 50, 80, 60, 62, 54, 34, 66, 60, 55, 50, 45],
            type: "line",
            symbol: "circle",
            symbolSize: 3,
            itemStyle: {
              color: "#F4B4FF",
              shadowColor: "#F4B4FF",
              shadowBlur: 10,
              borderColor: "#F4B4FF",
              borderWidth: 3,
              lineStyle: {
                color: "#F4B4FF",
              },
            },
          },
        ],
      },
      tableData: [],
      townList: [
        {
          name: "政策新动态",
          url: require("@/assets/tourism/z_1.png"),
          num: "48",
          unit: "条",
        },
        {
          name: "三务公开",
          url: require("@/assets/tourism/z_2.png"),
          num: "541",
          unit: "条",
        },
        {
          name: "视频直播",
          url: require("@/assets/tourism/z_3.png"),
          num: "56",
          unit: "场",
        },
        {
          name: "生活短视频",
          url: require("@/assets/tourism/z_4.png"),
          num: "346",
          unit: "条",
        },
        {
          name: "雪亮工程监控接入数",
          url: require("@/assets/tourism/z_5.png"),
          num: "2648",
          unit: "个",
        },
        {
          name: "入户广电管家数",
          url: require("@/assets/tourism/z_6.png"),
          num: "5415",
          unit: "",
        },
      ],
      cityList: [
        {
          name: "事件上报数",
          num: 26,
          unit: "件",
        },
        {
          name: "意见建议数",
          num: 64,
          unit: "条",
        },
        {
          name: "帮扶数",
          num: 36,
          unit: "次",
        },
        {
          name: "三务公开数",
          num: 10,
          unit: "次",
        },
      ],
      indexList: [
        {
          name: "幅员面积(k㎡)",
          num: 26,
        },
        {
          name: "耕地面积(亩)",
          num: 26,
        },
        {
          name: "常住人口数(人)",
          num: 26,
        },
        {
          name: "低保户/贫困户(人)",
          num: 26,
        },
        {
          name: "特殊人群(人)",
          num: 26,
        },
        {
          name: "正常党员(人)",
          num: 26,
        },
        {
          name: "预备党员(人)",
          num: 26,
        },
        {
          name: "积极分子(人)",
          num: 26,
        },
      ],
      indexUnit: ["k㎡", "亩", "人", "人", "人", "人", "人", "人"],
      colorList: [
        "#EB9E17",
        "#18BFE2",
        "#AB8EDF",
        "#25AE4F",
        "#EB9E17",
        "#18BFE2",
        "#AB8EDF",
        "#25AE4F",
      ],
      // 景点旅游数据
      spot: [],
      // 乡村业务
      summary: [],
      // 一镇一屏
      screen: [],
      Data: {},
      black: [],
      tlvIndex: 1,
      eventlist: [],
      richText:'',
      newList:[
        {
          title:'思想领航 凝聚奋进力量 | 简阳市宣传思想文化工作有声有色 亮点纷呈',
          content:`思想如炬，引领航向。党的十八大以来，以习近平同志为核心的党中央总揽全局，
          把宣传思想文化工作摆在重要位置，推动新时代宣传思想文化事业取得历史性成就。
          “宣传思想文化工作事关党的前途命运，事关国家长治久安，事关民族凝聚力和向心力，
          是一项极端重要的工作。”`
        },
        {
          title:'团结奋斗 真抓实干 推动全市宣传思想文化事业高质量发展 | 全市宣传思想文化',
          content:`思想如炬，引领航向。党的十八大以来，以习近平同志为核心的党中央总揽全局，
          把宣传思想文化工作摆在重要位置，推动新时代宣传思想文化事业取得历史性成就。
          “宣传思想文化工作事关党的前途命运，事关国家长治久安，事关民族凝聚力和向心力，
          是一项极端重要的工作。”`
        },
        {
          title:'【今日头条】简阳市召开大气污染防治工作第十一次专题会',
          content:`思想如炬，引领航向。党的十八大以来，以习近平同志为核心的党中央总揽全局，
          把宣传思想文化工作摆在重要位置，推动新时代宣传思想文化事业取得历史性成就。
          “宣传思想文化工作事关党的前途命运，事关国家长治久安，事关民族凝聚力和向心力，
          是一项极端重要的工作。”`
        }
      ]
    };
  },
  methods: {
    getNewList(){
      getINFO('/system/zhlyHotInfo/list').then(res => {
        this.newList = res.data.rows
      })
    },
    openRichText(title,item){
      let richText = `<div>
        地点：${item.place}；
        事件：${item.content};
      </div>`
      this.openDialog(title,'',richText)
    },
    openRichText2(title,item){
      let richText = `<div>
        地点：${item.place}；
        车牌号：${item.features};
        地库图：<img src="${item.basementImg}" style="width:100%">
        <div>
        抓拍图：
        <img src="${item.snapshotImg}" style="width:100%">
        </div>
      </div>`
      this.openDialog(title,'',richText)
    },
    // 第三
    async geteventList() {
      const res = await eventList(1, 20);
      if (res.data.code == 200) {
        this.eventlist = res.data.rows;
      }
    },
    initECharts() {
      const dom = document.getElementById("homeEchart");
      const myChart = echarts.init(dom);
      myChart.setOption(this.option);
      const dom1 = document.getElementById("homeEchart1");
      const myChart1 = echarts.init(dom1);
      myChart1.setOption(this.option1);
      const dom2 = document.getElementById("homeEchart2");
      const myChart2 = echarts.init(dom2);
      myChart2.setOption(this.option2);

      const dom3 = document.getElementById("homeEchart3");
      const myChart3 = echarts.init(dom3);
      myChart3.setOption(this.option3);
    },
    async getstaticList() {
      const res = await staticFn("scenic_spot_tourism_data");
      this.spot = res.data.data[0].dictValue.split(",");
      const rural = await staticFn("summary_rural_indicator_data");
      this.indexList = rural.data.data;

      const screen = await staticFn("overview_one_town_and_one_screen");
      this.screen = screen.data.data;
      const Data = await tongData();
      this.Data = Data.data.data;
      const Week = await tongWeek();
      const trend = await trafficflowtrend();
      const halfYearData = await halfYear();
      this.option1.xAxis.data = trend.data.data.map((i) => {
        return i.month;
      });
      this.option1.series[0].data = trend.data.data.map((i) => {
        return i.number;
      });
      this.option2.xAxis.data = Week.data.data.map((i) => {
        return i.date;
      });
      this.option2.series[0].data = Week.data.data.map((i) => {
        return i.femaleNumber;
      });
      this.option2.series[1].data = Week.data.data.map((i) => {
        return i.maleNumber;
      });
      this.option3.xAxis.data = halfYearData.data.data.map((i) => i.month);
      this.option3.series[0].data = halfYearData.data.data.map((i) => i.input);
      this.option3.series[1].data = halfYearData.data.data.map((i) => i.output);

      const black = await blacklist();
      this.black = black.data.rows;
      const people = await peopleflowtrend();
      this.option.xAxis.data = people.data.data.map((i) => {
        return i.date;
      });
      this.option.series[0].data = people.data.data.map((i) => {
        return {
          name: i.date,
          value: i.number,
        };
      });
      const summary = await businessSummary(1);
      this.summary = summary.data.data;
      this.$nextTick(() => {
        this.initECharts();
      });
    },
    async tlvClick(i) {
      this.tlvIndex = i;
      const summary = await businessSummary(i);
      this.summary = summary.data.data;
    },
  },
  created() {
    this.getstaticList();
    this.geteventList();
    this.getNewList()
  },
  mounted() {
    window.onresize = () =>
      (() => {
        this.getstaticList();
      })();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";
@keyframes fadeinR {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeinL {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.trtables {
  height: 80%;
  @include pt(10);
  @include pl(50);
  @include pr(50);
  box-sizing: border-box;
  overflow-y: scroll;
  cursor: pointer;
  th,
  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include height(40);
    @include lh(40);
    text-align: center;
  }
  th {
    color: #04f3f7;
    @include font(14);
  }
  tr {
    color: #dffdff;
    @include font(12);
  }
  td {
    width: 25%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .iamgs {
    display: inline-block;
    @include wd(24);
    @include height(24);
    @include br(2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  // overflow: hidden;
  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .MAPS {
    width: 100%;
    height: 53vw;
  }
}
.t {
  z-index: 888;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(transparent, rgba(0, 0, 0, 0.8) 55%);
  z-index: 1;
  @include pt(80);
  .fixbanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .t_l {
    // width: 98%;
    // margin: 0 auto;
    box-sizing: border-box;
    pointer-events: auto;
    @include mt(40);
    animation: fadeinL 2s;
    .t_l_1 {
      // pointer-events: none;
      @include wd(484);
      @include height(219);
      position: relative;
      @include backimage("@/assets/tourism/t_1.png");
      .t_l_t {
        position: absolute;
        @include left(46);
        @include top(0);
        @include font(18);
        @include lh(36);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-style: italic;
        color: #04f3f7;
      }
      .t_l_v {
        position: absolute;
        @include left(15);
        @include top(48);
        @include wd(71);
        @include height(20);
        background: linear-gradient(180deg, #558bd6 0%, #2f5b98 100%);
        box-shadow: 0 0.2vw 0.2vw 0 rgba(19, 71, 141, 0.56);
        @include br(10);
        @include font(12);
        @include lh(20);
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
      .t_l_c {
        position: absolute;
        @include top(77);
        @include left(44);
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .t_l_l {
          width: 50%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @include mb(29);
          .t_l_l_r {
            display: flex;
            flex-direction: column;
            @include ml(6);
            @include mb(12);
            .t_l_l_r_t {
              white-space: nowrap;
              @include font(14);
              @include lh(20);
              @include wd(96);
              @include height(20);
              @include ml(10);
              background-image: url("@/assets/smartTour/title_bg.png");
              background-repeat: no-repeat;
              background-position: left center;
              background-size: contain;
              color: #fff;
            }
            .t_l_l_r_b {
              @include height(19);
              @include font(22);
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #04f5f7;
              @include lh(27);
              span {
                color: #aed4d4;
                @include font(12);
              }
            }
          }
        }
        .t_l_z {
          width: 33.3%;
        }
        .t_l_s {
          width: 50%;
          @include mb(29);
          .t_sex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @include font(20);
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #04f5f7;
            i {
              @include wd(6);
              @include height(6);
              background: #ff9141;
              box-shadow: 0.1vw 0.2vw 0.6vw 0 rgba(255, 145, 65, 0.8);
              border-radius: 50%;
              @include mr(9);
            }
            .man {
              background: #2fa4ff;
            }
            span {
              @include font(14);
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              @include mr(12);
            }
          }
        }
      }
      .t_l_h {
        width: 90%;
        height: 75%;
        margin: 0 auto;
        padding-top: 10%;
      }
      .t_l_over {
        @include mt(64);
        @include ml(50);
        @include height(142);
        overflow: hidden;
        overflow-y: scroll;
        .t_l_table {
          text-align: center;
          border-collapse: collapse;
          cursor: pointer;
          .t_t_th {
            @include wd(90);
            @include font(14);
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #01f0ff;
          }
          .t_t_td {
            @include wd(57);
            @include height(36);
            @include font(12);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #dffdff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-bottom: 0.1vw solid rgba(48, 130, 210, 0.8);
          }
          .t_t_image {
            @include wd(24);
            @include height(24);
          }
        }
      }
      .t_l_over::-webkit-scrollbar {
        width: 0;
      }
      .t_l_p_r {
        display: flex;
        width: 100%;
        height: 100%;
        .t_l_p {
          @include mt(56);
          @include ml(33);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .t_l_p_l {
            @include wd(4);
            @include height(15);
            background: #28d86e;
            @include br(2);
            display: inline-block;
            @include mr(6);
          }
          span {
            @include font(16);
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #28d86e;
          }
        }
        .t_t_tol {
          @include font(12);
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #aed4d4;
          position: relative;
          display: flex;
          align-items: flex-end;
          @include mt(34);
          @include ml(33);
          .t_t_total {
            position: absolute;
            @include top(2);
            @include left(7);
            @include font(14);
            color: #ffffff;
          }
          .t_t_num {
            @include font(22);
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #04f5f7;
            @include ml(25);
          }
          .ml0 {
            @include ml(2);
          }
        }
      }
    }
    .t_l_2 {
      // pointer-events: none;
      @include wd(680);
      @include backimage("@/assets/tourism/t_z.png");
    }
    //右边
    .t_l_3 {
      // pointer-events: auto;
      @include wd(483);
      @include height(188);
      position: relative;
      @include backimage("@/assets/tourism/t_r.png");
      .t_l_t {
        position: absolute;
        @include right(55);
        @include top(0);
        @include font(18);
        @include lh(36);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-style: italic;
        color: #04f3f7;
      }
      .t_l_v {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include mr(32);
        @include pt(60);
        box-sizing: border-box;
        .t_l_v_i {
          @include wd(52);
          @include height(20);
          border: 0.1vw solid #448ee4;
          @include font(12);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #5aa8f0;
          text-align: center;
          @include lh(20);
          @include ml(9);
          cursor: pointer;
        }
        .t_l_v_a {
          background: rgba(68, 142, 228, 0.2);
          color: #fff;
        }
      }
      .t_l_list {
        @include mt(22);
        @include ml(30);
        @include mr(30);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
        .t_l_i {
          position: relative;
          white-space: nowrap;
          cursor: pointer;
          .iamges {
            @include wd(95);
            @include height(10);
          }
        }
        span {
          position: absolute;
          @include top(-8);
          @include left(7);
          @include font(14);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .t_l_b {
          @include font(22);
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #04f5f7;
          i {
            @include ml(5);
            color: #aed4d4;
            @include font(12);
          }
        }
      }
    }
    .t_l_4 {
      // pointer-events: none;
      @include wd(485);
      @include height(219);
      position: relative;
      @include backimage("@/assets/tourism/t_r_c.png");
      .homeEchart3 {
        @include pt(49);
        @include ml(24);
        width: 80%;
        height: 80%;
      }
      .t_l_t {
        position: absolute;
        @include right(55);
        @include top(0);
        @include font(18);
        @include lh(36);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-style: italic;
        color: #04f3f7;
      }
    }
    .t_l_5 {
      // pointer-events: none;
      float: right;
      @include wd(485);
      @include height(219);
      position: relative;
      @include backimage("@/assets/tourism/t_r_c.png");
      .t_l_t {
        position: absolute;
        @include right(55);
        @include top(0);
        @include font(18);
        @include lh(36);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-style: italic;
        color: #04f3f7;
      }
      .t_l_list {
        @include mt(76);
        @include ml(28);
        @include mr(20);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .t_l_i {
          position: relative;
          text-align: center;
          @include mb(36);
        }
        span {
          @include font(18);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .t_l_b {
          @include font(14);
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #cad1ff;
          i {
            @include ml(5);
            color: #aed4d4;
            @include font(12);
          }
        }
      }
    }
  }
  .t_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
#homeEchart2 {
  width: 100%;
  height: 80%;
}
::-webkit-scrollbar {
  border-radius: 5px;
  height: 10px;
  background-color: transparent;
  display: none !important;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  display: none !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
  display: none !important;
}
.uls {
  color: #01f0ff;
  @include pl(20);
  @include pr(20);
  @include pt(50);
  @include font(12);
  box-sizing: border-box;
  cursor: pointer;
  @include height(200);
  overflow-y: scroll;
  li {
    @include mt(10);
    text-align: left;
    @include ml(20);
  }
}
</style>