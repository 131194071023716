import service from "@/utils/requestFn";
// 人流量趋势
export function peopleflowtrend() {
    return service({
        url: `/system/peopleflowtrend/week`
    })
}
// 车流量趋势
export function trafficflowtrend() {
    return service({
        url: `/system/trafficflowtrend/year`
    })
}
// 乡村业务数据汇总
export function businessSummary(type) {
    return service({
        url: `/system/business/summary/${type}`
    })
}
// 用户情况
export function tongData() {
    return service({
        url: `/system/tong/data`
    })
}
// 用户最近一周新增趋势
export function tongWeek() {
    return service({
        url: `/system/tong/week`
    })
}
//黑名单预警
export function blacklist() {
    return service({
        url: `/system/blacklist/list`
    })
}
//近半年乡村登记信息
export function halfYear() {
    return service({
        url: `/system/epidemic/half/year`
    })
}
// 获取经纬度
export function addressOne(longitude,latitude) {
    return service({
        url: `system/address/one?longitude=${longitude}&latitude=${latitude}`
    })
}
