<template>
  <div class="container">
    <!-- <div class="top">
      <top-bar :current="1"></top-bar>
    </div> -->
    <div class="fixbanner">
      <el-row type="flex" align="center" justify="space-between" :gutters="10">
        <el-col :span="7">
        <div style="height:90vh;overflow:scroll">
          <div class="tourism_data">
            <div class="left_titles">
              景点旅游数据
            </div>
            <div class="tourism_content">
              <div class="changes">
                视屏分屏
              </div>
              <div class="tourism-row">
                <div class="tourism-col">
                  <img src="@/assets/smartTour/f1.png" />
                  <div>
                    <div class="littel_type">人流量统计</div>
                    <div class="data">{{senicSpot[0]}}<span class="tips"> (人)</span></div>
                  </div>
                </div>
                <div class="tourism-col">
                  <div class="dats">
                    <div class="flex_data">
                      <span class="dot"></span>
                      <span>女性</span>
                      <span class="data" style="margin-left:1vw">{{senicSpot[4]}}%</span>
                    </div>
                    <div class="flex_data">
                      <span class="dot dot1"></span>
                      <span>男性</span>
                      <span class="data" style="margin-left:1vw">{{senicSpot[3]}}%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tourism-row">
                <div class="tourism-col">
                  <img src="@/assets/smartTour/f2.png" />
                  <div>
                    <div class="littel_type">感知设备</div>
                    <div class="data">{{senicSpot[2]}}<span class="tips"> (台)</span></div>
                  </div>
                </div>
                <div class="tourism-col">
                  <img src="@/assets/smartTour/f3.png" />
                  <div>
                    <div class="littel_type">
                      车流量统计
                    </div>
                    <div class="data">{{senicSpot[1]}}<span class="tips"> (辆)</span></div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
          <div class="tourism_data person_data">
            <div class="left_titles">
              人流量趋势
            </div>
            <div id="person_data_echarts"></div>
          </div>
          <div class="tourism_data person_data">
            <div class="left_titles">
              车流量趋势
            </div>
            <div id="car_data_echarts"></div>
          </div>
          <div class="tourism_data">
            <div class="left_titles">
              前端感知源建设统计
            </div>
            <div class="webdesign">
                <div id="designForm"></div>
                <div id="designPie"></div>
            </div>
          </div>
          <div class="tourism_data">
            <div class="left_titles">
              热门资讯
            </div>
            <div style="height:1vw"></div>
            <div class="trtables" style="height:80%;">
              <th>
                <td>标题</td>
                <td>事件内容</td>
              </th>
              <tr v-for="(item,index) in newList" 
              :key="index" @click="openDialog('热门资讯',item.content)">
                  <td>{{item.title}}</td>
                  <td style="width:70%">{{item.content}}</td>
              </tr>
            </div>
          </div>
          <div class="tourism_data">
            <div class="left_titles">
              景点数量
            </div>
            <div style="height:2vw">
            </div>
            <div class="dang_circle" @click="richTextList">
              <img src="@/assets/townScreen/dang_circle.png" />
              <div class="title">个数</div>
              <div class="nums">{{jdNum}}</div>
            </div>
          </div>
           <div class="tourism_data">
            <div class="left_titles">
              景点热度
            </div>
            <div style="height:2vw"></div>
            <div id="tourHot"></div>
          </div>
        </div>
        </el-col>
        <el-col :span="9">
          <div class="num_data">
            <div class="personNum">
              <div class="data">
                <span class="bold" v-if="today_dataList.length">{{today_dataList[0].dictValue}}</span><span>人/次</span>
              </div>
              <div class="tips">今日人流量</div>
            </div>
            <div class="personNum">
              <div class="data">
                <span class="bold" v-if="today_dataList.length">{{today_dataList[1].dictValue}}</span><span>辆/次</span>
              </div>
              <div class="tips">今日车流量</div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="height:90vh;overflow:scroll">
            <div style="pointer-events: auto;">
            <div style="display:flex;justify-content: flex-end">
              <div class="car_num_fenxi">
              <div class="right_tiles">
                车流量分析
              </div>
              <div id="designForm1" style="width:90%;height:45%;"></div>
              <div class="trtables">
                <th>
                  <td>地点</td>
                  <td>车牌号</td>
                  <td>图片</td>
                </th>
                <tr v-for="(item,index) in carList" :key="index" @click="openRichText('车流量分析-详情',item)">
                    <td>{{item.place}}</td>
                    <td>{{item.licensePlateNumber}}</td>
                    <td>
                      <div class="iamgs" :style="'background-image:url('+item.img+')'"></div>
                    </td>
                </tr>
              </div>
              </div>
            </div>
            <div style="display:flex;justify-content: flex-end;">
              <div class="persondataNum">
                <div class="right_tiles">
                  人流量实时数据
                </div>
                <div class="trtables" style="height:80%">
                  <th>
                    <td style="wdith:30%">地点</td>
                    <td style="wdith:20%">性别</td>
                    <td>年龄段</td>
                    <td>图片</td>
                  </th>
                  <tr v-for="(item,index) in personList" :key="index" @click="openRichText1('人流量实时数据-详情',item)">
                      <td style="wdith:30%">{{item.place}}</td>
                      <td style="wdith:20%">{{item.gender == 0?'男':item.gender == 1? '女':'未知'}}</td>
                      <td>{{item.ageGroup}}</td>
                      <td>
                        <div class="iamgs" :style="'background-image:url('+item.img+')'"></div>
                      </td>
                  </tr>
                </div>
              </div>
            </div>
            <div style="display:flex;justify-content: flex-end;">
              <div class="blackform">
                <div class="right_tiles">
                  黑名单预警
                </div>
                <div class="trtables" style="height:80%">
                  <th>
                    <td>地点</td>
                    <td>特征</td>
                    <td>抓拍图</td>
                    <td>地库图</td>
                  </th>
                  <tr v-for="(item,index) in blacktableData" :key="index" @click="openRichText2('黑名单预警-详情',item)">
                      <td>{{item.place}}</td>
                      <td>{{item.features}}</td>
                      <td>
                        <div class="iamgs" :style="'background-image:url('+item.snapshotImg+')'"></div>
                      </td>
                      <td>
                        <div class="iamgs" :style="'background-image:url('+item.basementImg+')'"></div>
                      </td>
                  </tr>
                </div>
              </div>
            </div>
            <div style="display:flex;justify-content: flex-end;">
              <div class="blackform">
                <div class="right_tiles">
                  景区资讯
                </div>
                <div class="trtables" style="height:80%;">
              <th>
                <td>标题</td>
                <td>事件内容</td>
              </th>
              <tr v-for="(item,index) in touridlist" 
              :key="index"  @click="openDialog('景区资讯',item.content)">
                  <td>{{item.title}}</td>
                  <td>{{item.content}}</td>
              </tr>
            </div>
              </div>
            </div>
            <div style="display:flex;justify-content: flex-end;">
              <div class="blackform">
                <div class="right_tiles">
                  景点介绍
                </div>
                <div>
                  <ul class="uls">
                    <li v-for="(item,index) in tourPic" :key="index"
                    @click="openDialogJQU('景点介绍',item.id)"
                    >
                      {{item.name}}
                    </li>
                  </ul>

                </div>
              </div>
            </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <com-dialog :dialogVisible="dialogVisible" :contentInfo="dialogContent" :richText="richText"
    :title="dialogtitle" @cancle="dialogVisible = false"></com-dialog>
  </div>
</template>

<script>
// import topBar from '@/components/topBar/index.vue'
// import gaodeMap from '@/components/gaodeMap/index.vue'
import {
  staticFn,
  blackList,
  person_dataList,
  car_dataList,
  carLists,
  personLists,
} from "@/api/tourism/api";
import comDialogMinx from './minixs'
import {getINFO} from '@/api/common'
export default {
  name: "MyTourism",
  mixins:[comDialogMinx],
  components: {
    // topBar,
    // gaodeMap
  },
  data() {
    return {
      richText:'',
      senicSpot: [],
      blacktableData: [],
      webcontruct: [],
      today_dataList: [],
      originData:[],
      carList: [],
      personList: [],
      trafficData: [],
      touridlist: [
        {
          title: "四川简阳景美好玩的这九大景点...",
          content: `四川简阳旅游景点较多，自驾到简阳旅游，
          推荐四川简阳景美好玩的九大景点有：简阳东来桃源景区、三岔湖景区、简阳白塔园、
          五凤山森林公园、简阳龙泉湖、简阳樱桃沟旅游区、简阳丹景山、
          鳌山公园、简阳人民公园等。`,
        },
        {
          title: "【图说·简阳】初冬暖阳就在这里",
          content: `银杏染金 人间便增添几笔诗意 漫步于简阳银杏树下 
          纵享初冬限定的极致浪漫 “黄金时代”已经到来 别忘记与银
          杏奔赴一场人间惊鸿宴`,
        },
      ],
      tipContent: "",
      title: "",
      isshow: false,
      tourPic:[],
      numbers:[
        {
          title:'简阳市人民政府',
          personNum:'249',
          carNum:'150'
        },
        {
          title:'东来桃源旅游景区',
          personNum:'150',
          carNum:'100'
        },
        {
          title:'成都天府国际机场',
          personNum:'1100',
          carNum:'543'
        },
        {
          title:'简阳市三岔河景区',
          personNum:'100',
          carNum:'75'
        },
        {
          title:'简阳市丹景台',
          personNum:'100',
          carNum:'75'
        },
        {
          title:'简阳南站',
          personNum:'500',
          carNum:'300'
        },
      ],
      jdNum:0,
      jqList:[],
      jqTop:[],
      newList:[]
    };
  },
  mounted() {
    // window.onresize = () =>
    //   (() => {
    //     this.InitPersonData();
    //     this.InitCarData();
    //     this.webLeft();
    //     this.webRight();
    //   })();
    this.tourHotEcharts()
    this.InitPersonData();
    this.InitCarData();
    this.getstaticList("construction_system");
    this.getstaticList("traffic_flow_analysis");
    this.$bus.$off('bus-event') // 取消 bus-event 事件
    this.$bus.$on('bus-event',params => {
        getINFO('/system/address/flow',params).then(res => {
          this.today_dataList = [
            {
              dictValue:res.data.data[0].personNum,
              dictLabel:'今日人流量'
            },
            {
              dictValue:res.data.data[0].carNum,
              dictLabel:'今日车流量'
            }
          ]
        })        
    })
    getINFO('/system/address/flow').then(res => {
        this.today_dataList = [
          {
            dictValue:res.data.data[0].personNum,
            dictLabel:'今日人流量'
          },
          {
            dictValue:res.data.data[0].carNum,
            dictLabel:'今日车流量'
          }
        ]
    })  
  },
  created() {
    this.getstaticList("scenic_spot_tourism_data");
    this.getstaticList("today_data");
    this.getblackList();
    this.getcarlist();
    this.getPersonList();
    this.getTUorNum()
  },
  methods: {
    async openDialogJQU(title,id){
      let content = ''
      const res = await getINFO(`/system/address/${id}`)
      if(res.data.data) content = res.data.data
      let richText = `<div style="margin-top:20px">
                        <div class="bold">${content.name}</div>
                        <div class="info">创建时间：${content.createTime}</div>
                        <div class="contentInfo">
                          <img src="${content.imgUrl}">
                          <div>${content.content}</div>
                        </div>
                      </div>`
      this.openDialog(title,'',richText)
    },
    // 景点个数
    getTUorNum(){
      Promise.all([
        getINFO('/system/address/total'),//景点个数
        getINFO('/system/address/top10'),//景区热度
        getINFO('/system/address/jdjs'),//景点介绍
        getINFO('/system/jqzx/list'),//景区资讯列表
        getINFO('/system/address/total/list'),//景区列表
        getINFO('/system/zhlyHotInfo/list')
      ]).then(res => {
        this.jdNum = res[0].data.data
        this.jqList = res[4].data.data
        this.jqTop = res[1].data.data
        this.tourPic = res[2].data.data
        this.touridlist = res[3].data.rows
        this.newList = res[5].data.rows
        this.tourHotEcharts()
      })
    },
    richTextList(){
      let richText = ``
      this.jqList.forEach(item => {
        richText += `
        <div style="margin-top:20px">
        <li>${item.name}</li></div>
        <div>
          <img src="${item.imgUrl}" style="width:100%" />
        </div>
        <div>${item.content}</div>
        `
      })
      this.openDialog('景点数量-详情','',richText)
    },
    openRichText(title,item){
      let richText = `<div>
        地点：${item.place}；
        车牌号：${item.licensePlateNumber};
        <img src="${item.img}" style="width:100%">
      </div>`
      this.openDialog(title,'',richText)
    },
    openRichText1(title,item){
      let richText = `<div>
        地点：${item.place}；
        性别：${item.gender === 1?'女':'男'};
        年龄段：${item.ageGroup};
        <img src="${item.img}" style="width:100%">
      </div>`
      this.openDialog(title,'',richText)
    },
    openRichText2(title,item){
      let richText = `<div>
        地点：${item.place}；
        车牌号：${item.features};
        地库图：<img src="${item.basementImg}" style="width:100%">
        <div>
        抓拍图：
        <img src="${item.snapshotImg}" style="width:100%">
        </div>
      </div>`
      this.openDialog(title,'',richText)
    },
    tourHotEcharts(){
      let option = {
          tooltip: {
              trigger: 'axis',
              formatter: "{b} <br> 热度: {c}"
          },
          grid: {
              left: '2%',
              right: '4%',
              bottom: '2%',
              top: '8%',
              containLabel: true
          },
          xAxis: {
              splitLine: {
                  show: false
              },
              type: 'value',
              min: 0,
              axisLine: {
                  lineStyle: {
                      color: '#fff'
                  }
              }
          },
          yAxis: {
              splitLine: {
                  show: false
              },
              type: 'category',
              data: this.jqTop.map(item => item.name),
              axisLine: {
                  lineStyle: {
                      color: '#fff'
                  }
              },
              axisLabel: {
                  show: true,
                  interval: 0,
                  rotate: 0,
                  margin: 10,
                  inside: false,
                  textStyle: {
                      //color: '#fff',
                      fontWeight: '50'
                  }
              }
          },
          series: [{
              type: 'bar',
              barWidth: '15',
              label: {
                  normal: {
                      show: true,
                      // formatter: '{c}',
                      formatter: function(v) {
                          var val = v.data;
                          if (val == 0) {
                              return '';
                          }
                          return val;
                      },
                      color: '#fff'
                  }
              },
              itemStyle: {
                  emphasis: {
                      barBorderRadius: 7
                  },
                  normal: {
                      barBorderRadius: 4,
                      color: new this.$echarts.graphic.LinearGradient(
                          0, 0, 1, 0,
                          [{
                                  offset: 0,
                                  color: '#0AF8EB'
                              },
                              {
                                  offset: 1,
                                  color: '#B055F8'
                              }

                          ]
                      )
                  }
              },
              data: this.jqTop.map(item => item.num)
          }]
      }
      const dom = document.getElementById("tourHot");
      const myChart = this.$echarts.init(dom);
      myChart.setOption(option);
    },
    // 车列表
    async getcarlist() {
      const res = await carLists(1, 20);
      if (res.data.code == 200) {
        this.carList = res.data.rows;
      }
    },
    // 人列表
    async getPersonList() {
      const res = await personLists(1, 20);
      if (res.data.code == 200) {
        this.personList = res.data.rows;
      }
    },
    // 黑名单
    async getblackList() {
      const res = await blackList(1, 20);
      if (res.data.code == 200) {
        this.blacktableData = res.data.rows;
      }
    },
    // 人流量
    async InitPersonData() {
      let xLabel = [];
      let goToSchool = [];
      const res = await person_dataList();
      if (res.data.code == 200) {
        res.data.data.map((item) => {
          xLabel.push(item.date);
          goToSchool.push(item.number);
        });
      }
      let myEcharts = this.$echarts.init(
        document.getElementById("person_data_echarts")
      );
      let option = {
        grid: {
          top: "20%",
          left: "15%",
          right: "15%",
          bottom: "20%",
          // containLabel: true
        },
        xAxis: [
          {
            // type: 'category',
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#fff",
                padding: 12,
                fontSize: 12,
              },
              formatter: function (data) {
                return data;
              },
            },
            data: xLabel,
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: 10,
            },
            min: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                padding: 16,
              },
              formatter: function (value) {
                if (value === 0) {
                  return value;
                }
                return value;
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          confine: true,
          backgroundColor: "rgba(0,0,0,0.5)",
          borderWidth: 0,
          textStyle: {
            color: "#fff", //设置文字颜色
          },
          formatter: function (params) {
            let html = "";
            html += `${params[0].axisValueLabel} <br> 人流量为：${params[0].value}人`;
            return html;
          },
        },
        series: [
          {
            type: "line",
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 12,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
                color: "#FFAB3F", // 线条颜色
              },
              type: "solid",
              // borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fff",
              borderWidth: 2,
              borderColor: "#FFD336",
            },
            label: {
              show: true,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(255, 171, 63,.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(254, 211, 12,0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                shadowBlur: 20,
              },
            },
            data: goToSchool,
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 车流量
    async InitCarData() {
      let xLabel = [];
      let goToSchool = [];
      const res = await car_dataList();
      if (res.data.code == 200) {
        res.data.data.map((item) => {
          xLabel.push(item.month);
          goToSchool.push(item.number);
        });
      }
      let myEcharts = this.$echarts.init(
        document.getElementById("car_data_echarts")
      );

      let option = {
        grid: {
          top: "20%",
          left: "15%",
          right: "15%",
          bottom: "20%",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          confine: true,
          backgroundColor: "rgba(0,0,0,0.5)",
          borderWidth: 0,
          textStyle: {
            color: "#fff", //设置文字颜色
          },
          formatter: function (params) {
            let html = "";
            html += `${params[0].axisValueLabel} <br> 车流量为：${params[0].value}辆`;
            return html;
          },
        },
        xAxis: [
          {
            // type: 'category',
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#fff",
                padding: 12,
                fontSize: 10,
              },
              rotate: "30",
              formatter: function (data) {
                return data;
              },
            },
            splitLine: {
              show: false,
            },
            data: xLabel,
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: 10,
            },
            min: 0,
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(101, 124, 168, 0.4)",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                padding: 16,
              },
              formatter: function (value) {
                if (value === 0) {
                  return value;
                }
                return value;
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "line",
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 12,
            lineStyle: {
              normal: {
                width: 3,
                color: "#4EFDFF", // 线条颜色
              },
              type: "solid",
              // borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: "#fff",
              borderWidth: 2,
              borderColor: "#4EFDFF",
            },
            label: {
              show: true,
            },
            areaStyle: {
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(4, 243, 247,.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(4, 243, 247,0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: goToSchool,
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 前端感知左(未)
    webLeft(id, data) {
      let cuLits = [];
      let maxList = [];
      let nameList = [];
      data.map((item) => {
        let list = item.dictValue.split(",");
        cuLits.push(list[0]);
        maxList.push(list[1]);
        nameList.push(item.dictLabel);
      });
      cuLits.reverse();
      maxList.reverse();
      nameList.reverse();
      let myEcharts = this.$echarts.init(document.getElementById(id));
      let option = {
        grid: {
          left: "20",
          top: "10",
          right: "40",
          bottom: "0",
          containLabel: true,
        },
        tooltip: {
          type: "item",
        },
        xAxis: {
          type: "value",
          splitLine: { show: false }, //坐标轴在 grid 区域中的分隔线
          axisLabel: { show: false }, //坐标轴刻度标签
          axisTick: { show: false }, //坐标轴刻度
          axisLine: { show: false }, //坐标轴轴线
        },
        yAxis: {
          type: "category",
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            color: "#C7D6F0",
            fontSize: 12,
          },
          data: nameList,
        },
        series: [
          {
            name: "",
            type: "bar", //柱状、条形图
            barWidth: 15, //柱条的宽度,默认自适应
            data: cuLits, //当前值
            label: {
              show: true,
              position: "right", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}{a}",
              color: "rgba(255,255,255,.7)", //标签字体颜色
              fontSize: 12, //标签字号
            },
            itemStyle: {
              //图形样式
              normal: {
                barBorderRadius: 10,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 1, [
                  { offset: 0, color: "#1296E9" },
                  { offset: 0.5, color: "#40A5EF" },
                  { offset: 1, color: "#1296E9" },
                ]),
              },
            },
            zlevel: 1,
          },
          {
            name: "",
            type: "bar",
            label: {
              show: true,
              position: "right",
              offset: [0, 0],
              formatter: "{c}{a}",
              color: "#C7D6F0",
            },
            barGap: "-100%",
            barWidth: 15,
            data: maxList, //最大值
            color: "rgba(0, 0, 0,.2)", //柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 10,
                borderWidth: 1,
                borderColor: "#13E1E3",
              },
            },
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 前端感知右
    webRight() {
      let cuLits = [];
      let maxList = [];
      let nameList = [];
      this.webcontruct.map((item) => {
        let list = item.dictValue.split(",");
        cuLits.push(list[0]);
        maxList.push(list[1]);
        nameList.push(item.dictLabel);
      });
      let myEcharts = this.$echarts.init(document.getElementById("designPie"));
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          x: "center",
          data: ["建设"],
          show: false,
        },
        radar: [
          {
            indicator: (function () {
              var res = [];
              nameList.forEach((item, index) => {
                res.push({ text: item, max: maxList[index] });
              });
              return res;
            })(),
            center: ["48%", "40%"],
            radius: 60,
            shape: "circle",
            axisName: {
              formatter: "{value}",
              color: "rgba(199, 214, 240, 1)",
              fontSize: 12,
            },
            splitArea: {
              show: false,
              areaStyle: {
                color: "rgba(255,0,0,0)", // 图表背景的颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(78, 228, 255, 1)",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                type: "dotted",
                color: "rgba(78, 228, 255, 1)", // 设置网格的颜色
              },
            },
          },
        ],
        lineStyle: {
          width: 2,
          type: "dotted",
          join: "round",
        },
        label: {
          position: "left",
        },
        series: [
          {
            type: "radar",
            areaStyle: {
              color: "rgba(36, 124, 255, 0.34)",
            },
            symbolSize: 2,
            symbolColor: "rgba(18, 153, 234, 1)",
            data: [
              {
                name: "建设",
                value: cuLits,
              },
            ],
          },
        ],
      };
      myEcharts.setOption(option);
    },
    // 景点旅游数据
    async getstaticList(type) {
      const res = await staticFn(type);
      if (res.data.code == 200) {
        if (type == "scenic_spot_tourism_data") {
          this.senicSpot = res.data.data[0].dictValue;
          this.senicSpot = this.senicSpot.split(",");
        }
        if (type == "construction_system") {
          this.webcontruct = res.data.data;
          this.webLeft("designForm", this.webcontruct);

          this.webRight();
        }
        if (type == "traffic_flow_analysis") {
          this.trafficData = res.data.data;
          this.webLeft("designForm1", this.trafficData);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
@keyframes fadeinR {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeinL {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .fixbanner {
    z-index: 888;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.8) 55%);
    @include pt(80);
    // @include pl(20);
    // @include pr(20);
  }
  .tourism_data {
    pointer-events: auto;
    animation: fadeinL 2s;
    color: #fff;
    @include wd(500);
    @include height(230);
    @include mt(30);
    position: relative;
    @include backimage("@/assets/smartTour/left_bg.png");
    .tourism_content {
      position: absolute;
      @include top(50);
      left: 0;
      right: 0;
      bottom: 0;
      @include pl(15);
      @include pr(15);
      @include pt(10);
      .changes {
        @include wd(71);
        @include height(20);
        text-align: center;
        background: linear-gradient(180deg, #558bd6 0%, #2f5b98 100%);
        box-shadow: 0 0.2vw 0.2vw 0 rgba(19, 71, 141, 0.56);
        @include br(10);
        @include font(12);
        @include lh(20);
      }
      .tourism-row {
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include mt(5);
      }
      .tourism-col {
        width: 50%;
        display: flex;
        align-items: center;
        img {
          @include wd(74);
          @include height(47);
          @include ml(10);
        }
        .dats {
          @include ml(20);
          .data {
            @include ml(30);
          }
        }
        .flex_data {
          width: 100%;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          .data {
            @include ml(12);
          }
        }
        .tips {
          color: #aed4d4;
          @include font(12);
        }
      }
      .data {
        @include font(22);
        color: #04f5f7;
        font-weight: bold;
      }
      .dot {
        display: inline-block;
        @include wd(6);
        @include height(6);
        border-radius: 50%;
        background-color: #ff9141;
        box-shadow: 0.2vw 0.2vw 0.5vw 0 rgba(255, 145, 65, 0.8);
        @include mr(10);
        @include font(14);
      }
      .dot1 {
        background: #2fa4ff;
        box-shadow: 0.2vw 0.2vw 0.5vw 0 rgba(47, 164, 255, 0.8);
      }
    }
  }
}
.MAPS {
  width: 100vw;
  height: 55vw;
}
.left_titles {
  position: absolute;
  @include top(5);
  left: 10%;
  color: #04f3f7;
  @include font(18);
  font-weight: bold;
  font-style: italic;
}
.right_tiles {
  position: absolute;
  @include top(5);
  right: 10%;
  color: #04f3f7;
  @include font(18);
  font-weight: bold;
  font-style: italic;
}
.littel_type {
  @include font(14);
  @include lh(30);
  @include wd(96);
  @include height(30);
  @include ml(10);
  background-image: url("@/assets/smartTour/title_bg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}
#person_data_echarts {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}
#car_data_echarts {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}
.webdesign {
  @include mt(20);
  width: 100%;
  height: 100%;
  @include pt(40);
  @include pl(10);
  @include pr(10);
  display: flex;
}
#designForm {
  width: 45%;
  height: 80%;
}
#designPie {
  width: 50%;
}
.num_data {
  position: absolute;
  @include bottom(10);
  display: flex;
  align-items: center;
  .personNum {
    @include height(60);
    @include wd(134);
    @include backimage("@/assets/smartTour/bottom_bg.png");
    text-align: center;
  }
  .data {
    @include font(12);
    @include mt(10);
    color: #fff;
    .bold {
      @include font(22);
      font-weight: bold;
    }
  }
  .tips {
    color: rgba(62, 206, 253, 1);
    @include font(14);
  }
}
.trtables {
  height: 50%;
  @include pt(22);
  @include pl(50);
  @include pr(50);
  box-sizing: border-box;
  overflow-y: scroll;
  cursor: pointer;
  th,
  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include height(40);
    @include lh(40);
    text-align: center;
  }
  th {
    color: #04f3f7;
    @include font(14);
  }
  tr {
    color: #dffdff;
    @include font(12);
  }
  td {
    width: 25%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .iamgs {
    display: inline-block;
    @include wd(24);
    @include height(24);
    @include br(2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.car_num_fenxi {
  @include wd(487);
  position: relative;
  @include backimage("@/assets/smartTour/right_bg1.png");
  @include height(420);
  @include pt(50);
  @include pl(20);
  animation: fadeinR 2s;
}
.persondataNum {
  @include wd(487);
  position: relative;
  @include backimage("@/assets/smartTour/right_bg.png");
  @include height(221);
  @include pt(20);
  @include pl(20);
  @include mt(30);
  animation: fadeinR 2s;
}
.blackform {
  @include wd(487);
  position: relative;
  @include backimage("@/assets/smartTour/right_bg.png");
  @include height(220);
  @include pt(20);
  @include pl(20);
  @include mt(30);
  animation: fadeinR 2s;
}
::-webkit-scrollbar {
  border-radius: 5px;
  height: 10px;
  background-color: transparent;
  display: none !important;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  display: none !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
  display: none !important;
}
.dialogcontent {
  border: 1px solid #04b8bb;
  padding: 20px;
  background-color: rgba(40, 72, 77, 0.3);
  .flexs {
    display: flex;
    justify-content: space-between;
  }
  .title {
    color: #04f3f7;
  }
  img {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}
.dang_circle {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    @include wd(184);
    @include height(154);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }

  .title {
    color: #fff;
    @include font(18);
    position: absolute;
    top: 22%;
    left: 23%;
  }

  .nums {
    @include wd(100);
    @include height(50);
    @include font(30);
    font-weight: bold;
    color: #fdbf46;
    background: linear-gradient(0deg, #faff67 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-35%, -45%);
  }
}
.uls{
  color: #01f0ff;
  @include pl(20);
  @include pr(20);
  @include pt(20);
  @include font(14);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  cursor: pointer;
  li{
    @include mt(10);
    text-align: left;
    @include ml(20);
    width:40%;
  }
  overflow: scroll;
}
#tourHot{
  width: 75%;
  height: 80%;
}
.conettns{
  margin-top: 1vw;
  color: #bacbcc;
}
</style>