import service from "@/utils/requestFn";


// 人人通-用户情况
export function tong_data() {
    return service({
        url: `/system/tong/data`
    })
}

// 人人通-用户最近一周新增趋势
export function tong_week() {
    return service({
        url: `/system/tong/week`
    })
}