<template>
  <div class="container">
    <!-- <div class="top">
      <top-bar :current="2"></top-bar>
    </div> -->
    <div class="fixbanner">
      <el-row type="flex" align="center" justify="space-between" :gutters="10">
        <el-col :span="8">
         <div style="height:90vh;overflow:scroll">
          <div style="pointer-events: auto">
          <div class="tourism_data" style="margin-top:0">
            <div class="left_titles">
              乡村业务数据汇总
            </div>
            <div class="tourism_content">
              <div class="select_Bar">
                <div class="bar" :class="typeIndex == item.value?'active':''"
                v-for="(item,index) in typeList" :key="index" @click="tabClick(item.value)">
                  {{item.name}}
                </div>
              </div>
              <div class="tourism-row">
                <div class="tourism-col" 
                @click="openDialog('乡村业务数据汇总-详情','事件上报数：'+summary[0].assistanceNumber,'')">
                  <div>
                    <div class="littel_type">事件上报数</div>
                    <div class="data" v-if="summary.length > 0">{{summary[0].assistanceNumber}}<span class="tips"> (件)</span></div>
                  </div>
                </div>
                <div class="tourism-col"
                @click="openDialog('乡村业务数据汇总-详情','意见建议数：'+summary[0].commentsNumber,'')"
                >
                  <div>
                    <div class="littel_type">意见建议数</div>
                    <div class="data" v-if="summary.length > 0">{{summary[0].commentsNumber}}<span class="tips"> (条)</span></div>
                  </div>
                </div>
                <div class="tourism-col"
                @click="openDialog('乡村业务数据汇总-详情','帮扶数：'+summary[0].eventsNumber,'')"
                >
                  <div>
                    <div class="littel_type">帮扶数</div>
                    <div class="data" v-if="summary.length > 0">{{summary[0].eventsNumber}}<span class="tips"> (次)</span></div>
                  </div>
                </div>
                <div class="tourism-col"
                @click="openDialog('乡村业务数据汇总-详情','三务公开数：'+summary[0].publicNumber,'')"
                >
                  <div>
                    <div class="littel_type">
                      三务公开数
                    </div>
                    <div class="data" v-if="summary.length > 0">{{summary[0].publicNumber}}<span class="tips">(次)</span></div>
                  </div>
                </div>
              </div>           
            </div>
          </div>
          <div class="rutalIndex">
            <div class="left_titles">
              乡村指标数据汇总
            </div>
            <div class="list">
              <div class="t_l_i" v-for="(i, index) in indexList" :key="index">
                <div class="iamges">
                  <img :src="require('@/assets/rural/'+imageList[index])">
                  <span :style="{ color: colorList[index] }">{{ i.dictValue }}</span>
                </div>
                <div class="t_l_b">{{ i.dictLabel }}({{ indexUnit[index] }})</div>
              </div>
          </div>
          </div>
          <div class="hotlist">
            <div class="left_titles">
              乡村热点事件
            </div>
            <div class="trtables">
              <th>
                <td>标题</td>
                <td>事件地点</td>
                <td>事件内容</td>
                <td>处理描述</td>
              </th>
              <tr v-for="(item,index) in eventlist" :key="index"
              @click="openDialog('乡村热点事件-详情',item.content,'')"
              >
                  <td>{{item.title}}</td>
                  <td>{{item.place}}</td>
                  <td>{{item.content}}</td>
                  <td>{{item.remark}}</td>
              </tr>
            </div>
          </div>
          <div class="hotlist">
            <div class="left_titles">
              专题热点的舆情总数
            </div>
            <div class="dang_circle" @click="richTextList">
              <img src="@/assets/townScreen/dang_circle.png" />
              <div class="title">舆情总数</div>
              <div class="nums">{{yqNum}}</div>
            </div>
          </div>
          <div class="hotlist">
            <div class="left_titles">
              专题热点的情感指数
            </div>
            <div id="qinganEcharts"></div>
          </div>
          <div class="hotlist">
            <div class="left_titles">
              热门话题TOP榜
            </div>
            <div id="topEcharts"></div>
          </div>
          </div>
         </div>
        </el-col>
        <el-col :span="8">
        </el-col>
        <el-col :span="8">
          <div style="height:90vh;overflow:scroll">
            <div style="pointer-events: auto;">
              <div style="display:flex;justify-content: flex-end">
                <div class="yiqingcontent">
                  <div class="right_tiles">
                    成都市实时数据
                  </div>
                  <div class="yiqing">
                      <div class="bars" v-for="(item,index) in yiqingList" :key="index" 
                      @click="openDialog('成都市实时数据-详情',item.dictLabel+'：'+item.dictValue)">
                        <div>{{item.dictLabel}}</div>
                        <div class="data">{{item.dictValue}}<span class="tips"> (人)</span></div>
                      </div>
                  </div>
                </div>
              </div>
              <div style="display:flex;justify-content: flex-end;">
                <div class="formIn">
                  <div class="right_tiles">
                    近半年乡村人员流动登记信息
                  </div>
                  <div class="homeEchart3" id="homeEchart3"></div>
                </div>
              </div>
              <div style="display:flex;justify-content: flex-end;">
                <div class="blackform">
                  <div class="right_tiles">
                    近半年乡村人员疫苗接种信息
                  </div>
                  <div id="yimiaoechtas"></div>
                </div>
              </div>
              <div style="display:flex;justify-content: flex-end;">
              <div class="blackform">
                <div class="right_tiles">
                  专题热点的热度指数
                </div>
                <div id="hotIndexEcharts"></div>
              </div>
              </div>
              <div style="display:flex;justify-content: flex-end;">
              <div class="blackform">
                <div class="right_tiles">
                  媒体覆盖指数
                </div>
                <div id="coverEcharts"></div>
              </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <com-dialog :dialogVisible="dialogVisible" :contentInfo="dialogContent" :richText="richText"
    :title="dialogtitle" @cancle="dialogVisible = false"></com-dialog>
  </div>
</template>

<script>
import { staticFn } from "@/api/tourism/api";
import { businessSummary, halfYear } from "@/api/index";
import { eventList, yimiao } from "@/api/rural/api";
import comDialogMinx from './minixs'
import {getINFO} from '@/api/common'
export default {
  name: "townScreen",
  mixins:[comDialogMinx],
  data() {
    return {
      dialogVisible: false,
      dialogtitle: "",
      oneTownScreen: [],
      blacktableData: [],
      webcontruct: [],
      today_dataList: [],
      carList: [],
      personList: [],
      trafficData: [],
      activeTotal: [],
      NewDangList: [],
      richText:'',
      typeList: [
        {
          name: "上月",
          value: 1,
        },
        {
          name: "近半年",
          value: 2,
        },
        {
          name: "近一年",
          value: 3,
        },
      ],
      indexList: [
        {
          name: "幅员面积(k㎡)",
          num: 26,
        },
        {
          name: "耕地面积(亩)",
          num: 26,
        },
        {
          name: "常住人口数(人)",
          num: 26,
        },
        {
          name: "低保户/贫困户(人)",
          num: 26,
        },
        {
          name: "特殊人群(人)",
          num: 26,
        },
        {
          name: "正常党员(人)",
          num: 26,
        },
        {
          name: "预备党员(人)",
          num: 26,
        },
        {
          name: "积极分子(人)",
          num: 26,
        },
      ],
      colorList: [
        "#EB9E17",
        "#18BFE2",
        "#AB8EDF",
        "#25AE4F",
        "#EB9E17",
        "#18BFE2",
        "#AB8EDF",
        "#25AE4F",
      ],
      imageList: [
        "circle_1.png",
        "circle_2.png",
        "circle_3.png",
        "circle_4.png",
        "circle_1.png",
        "circle_2.png",
        "circle_3.png",
        "circle_4.png",
      ],
      indexUnit: ["k㎡", "亩", "人", "人", "人", "人", "人", "人"],
      typeIndex: 1,
      summary: [],
      eventlist: [],
      yiqingList: [],
      option3: {
        grid: {
          top: "10%",
          left: "1%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          backgroundColor: "rgba(255, 132, 90, 0.3)",
          borderColor: "#FF845A",
          extraCssText: "box-shadow: 2px 2px 4px 0px rgba(255, 132, 90, 0.3);",
          textStyle: {
            fontSize: 16,
            color: "#fff",
            fontFamily: "PangMenZhengDao",
          },
          formatter: function (params) {
            var relVal = params[0].name;
            for (var i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                ": " +
                params[i].value +
                " 条";
            }
            return relVal;
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#FF845A",
            },
          },
        },
        legend: {
          orient: "horizontal",
          left: "3%",
          align: "left",
          textStyle: {
            color: "#DEEBFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular",
          },
        },
        xAxis: {
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          boundaryGap: true,
          axisLine: {
            show: true,
            symbol: ["none", "rect"],
            symbolSize: [6, 12],
            lineStyle: {
              width: 2,
              color: "#537DAA",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          // name: "(万条)",
          nameTextStyle: {
            color: "#AEC9FF",
            fontWeight: 400,
            fontSize: 14,
          },
          axisLabel: {
            color: "#AEC9FF",
          },
          axisLine: {
            show: true,
            symbol: ["none", "rect"],
            symbolSize: [6, 12],
            lineStyle: {
              width: 2,
              color: "#537DAA",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(83, 125, 170, 0.2)",
            },
          },
        },
        series: [
          {
            name: "返乡登记数",
            data: [60, 80, 100, 70, 65, 69, 80, 100, 70, 65, 69, 66],
            type: "line",
            symbol: "circle",
            symbolSize: 3,
            itemStyle: {
              color: "#5EB8FF",
              shadowColor: "#5EB8FF",
              shadowBlur: 10,
              borderColor: "#5EB8FF",
              borderWidth: 3,
              lineStyle: {
                color: "#5EB8FF",
              },
            },
          },
          {
            name: "外出登记数",
            data: [70, 50, 80, 60, 62, 54, 34, 66, 60, 55, 50, 45],
            type: "line",
            symbol: "circle",
            symbolSize: 3,
            itemStyle: {
              color: "#F4B4FF",
              shadowColor: "#F4B4FF",
              shadowBlur: 10,
              borderColor: "#F4B4FF",
              borderWidth: 3,
              lineStyle: {
                color: "#F4B4FF",
              },
            },
          },
        ],
      },
      yuqList:[],
      yqNum:'',
      coverObj:{}
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initalData();
      this.InitYiqingData();
    });
    this.getCurrentInfo()
  },
  created() {
    this.getbusinessSummary(this.typeIndex);
    this.geteventList();
    this.getstaticList("summary_rural_indicator_data");
    this.getstaticList("real_time_epidemic_data");
  },
  methods: {
    getCurrentInfo(){
      Promise.all([
        getINFO('/system/ztrddyqzs/one'),//舆情总数
        getINFO('/system/ztrddyqzs/total'),//舆情列表
        getINFO('/system/mtfgzs/one'),//覆盖指数
        getINFO('/system/top/one'),//乡村振兴-热门话题top榜
        getINFO('/system/ztrddqgzs/one'),//情感指数
        getINFO('/system/ztrddrdzs/one')//专题热点的热度指数
      ]).then(res=> {
        this.yqNum = res[0].data.data
        this.yuqList = res[1].data.data
        this.coverObj = res[2].data.data
        this.coverEchartsFn()
        this.TOPecharts(res[3].data.data)
        this.qinganCharts(res[4].data.data)
        this.hotIndexCHAS(res[5].data.data);
      })
    },
    richTextList(){
      let richText = ``
      this.yuqList.forEach(item => {
        richText += `<div><li>${item.content}</li></div>`
      })
      this.openDialog('舆情总数-详情','',richText)
    },
    coverEchartsFn() {
      let itemStyle = {
        value: 1000,
        show: false,
        name: "",
        itemStyle: {
          normal: {
            color: "transparent",
          },
        },
      };

      const getDataList = () => {
        return [
          {
            value: this.coverObj.xmt,
            show: true,
            scales: "50%",
            name: "新媒体",
            itemStyle: {
              normal: {
                color: "#4ab9bc",
              },
            },
          },
          itemStyle,
          {
            value: this.coverObj.ds,
            scales: "25%",
            show: true,
            name: "电视",
            itemStyle: {
              normal: {
                color: "#1ab47b",
              },
            },
          },
          itemStyle,
          {
            value: this.coverObj.ctmt,
            show: true,
            scales: "10%",
            name: "传统媒体",
            itemStyle: {
              normal: {
                color: "#3876c4",
              },
            },
          },
          itemStyle,
          {
            value: this.coverObj.whmt,
            show: true,
            scales: "15%",
            name: "网红媒体",
            itemStyle: {
              normal: {
                color: "#f5d370",
              },
            },
          },
          itemStyle,
        ];
      };

      const dataList = getDataList();
      let legend = {
        selectedMode: false, // 取消图例上的点击事件
        type: "plain",
        orient: "vertical",
        left: "45%",
        top: "20%",
        align: "left",
        itemGap: 20,
        // itemWidth: 12, // 设置宽度
        // itemHeight: 7, // 设置高度
        symbolKeepAspect: false,
        textStyle: {
          color: "#000",
          rich: {
            name: {
              verticalAlign: "right",
              align: "left",
              width: 110,
              fontSize: 15,
              color: "#e6e6e6",
            },
            value: {
              align: "left",
              width: 50,
              fontSize: 15,
              color: "#fff",
            },
            un: {
              color: "#adafb3",
              fontSize: 14,
              align: "left",
            },
            scale: {
              fontSize: 16,
              color: "#fff",
              width: 50,
              align: "left",
            },
          },
        },
        data: dataList.map((item) => {
          if (item.show) {
            return item.name;
          }
        }),
        formatter: function (name) {
          if (dataList && dataList.length) {
            for (var i = 0; i < dataList.length; i++) {
              if (name === dataList[i].name) {
                return (
                  "{name| " +
                  name +
                  "} " +
                  "{scale|" +
                  dataList[i].scales +
                  "}" +
                  "{value| " +
                  dataList[i].value +
                  "}" +
                  "{un|单}"
                );
              }
            }
          }
        },
      };

      let option = {
        legend,
        series: [
          {
            itemStyle: {
              normal: {
                show: true,
              },
            },
            type: "pie",
            radius: ["40%", "60%"],
            center: ["20%", "45%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: getDataList(),
          },
          {
            itemStyle: {
              normal: {
                color: "rgba(0,177,242, 0.3)",
              },
            },
            type: "pie",
            hoverAnimation: false,
            radius: ["35%", "65%"],
            center: ["20%", "45%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 1,
              },
            ],
            z: -1,
          },
        ],
      };
      const dom = document.getElementById("coverEcharts");
      const myChart = this.$echarts.init(dom);
      myChart.setOption(option);
    },
    hotIndexCHAS(datas) {
      var data = [
        {
          value: datas.zz,
          name: "政治",
        },
        {
          value: datas.jj,
          name: "经济",
        },
        {
          value: datas.sh,
          name: "社会",
        },
        {
          value: datas.wh,
          name: "文化",
        },
        {
          value: datas.kj,
          name: "科技",
        },
      ];
      var total = 0;
      data.forEach((item) => {
        total += Number(item.value);
      });
      data.push({
        value: total,
        name: "",
        itemStyle: {
          normal: {
            color: "rgba(0,0,0,0)",
          },
        },
      });

      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            var str =
              params.name +
              "</br>" +
              "数量：" +
              params.value +
              "</br>" +
              "占比：" +
              ((params.data.value / total) * 100).toFixed(2) +
              "%";
            return str;
          },
        },

        legend: {
          top: "20",
          icon: "roundRect",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          itemWidth: 10,
          itemHeight: 10,
        },
        color: ["#5c7bd9", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        series: [
          {
            name: "",
            type: "pie",
            startAngle: -180,
            hoverAnimation: false,
            radius: ["50%", "95%"],
            center: ["50%", "75%"],
            data: data,
            label: {
              normal: {
                formatter: function (params) {
                  if (params.name) {
                    return (
                      params.data.name +
                      "(" +
                      ((params.data.value / total) * 100).toFixed(2) +
                      "%)"
                    );
                  }
                },
                color: "#fff",
              },
            },
            emphasis: {
              scale: true,
              scaleSize: 10,
            },
            labelLine: {
              normal: {
                length: 30,
                length2: 10,
                lineStyle: {
                  width: 1,
                },
              },
            },
          },
        ],
      };
      const hotIndexdom = document.getElementById("hotIndexEcharts");
      const myCharthotindex = this.$echarts.init(hotIndexdom);
      myCharthotindex.setOption(option);
    },
    qinganCharts(data) {
      let option = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}" + "%",
          backgroundColor: "rgba(1, 13, 19, 0.5)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(212, 232, 254, 1)",
          },
        },

        legend: {
          // orient: 'vertical',
          left: "center",
          top: "5%",
          itemWidth: 15,
          itemHeight: 10,
          itemGap: 25,
          borderRadius: 4,
          textStyle: {
            color: "#fff",
            fontFamily: "Alibaba PuHuiTi",
            fontSize: 12,
            fontWeight: 400,
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            data: [
              { value: data.zm, name: "正面" },
              { value: data.fm, name: "负面" },
            ],
            label: {
              normal: {
                formatter: ["{b|{b}}", "{c|{c}}"].join("\n"),
                rich: {
                  c: {
                    color: "inherit",
                    fontSize: 16,
                    fontWeight: "bold",
                    lineHeight: 5,
                  },
                  b: {
                    fontSize: 14,
                    height: 40,
                  },
                },
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      const qindom = document.getElementById("qinganEcharts");
      const myChartqing = this.$echarts.init(qindom);
      myChartqing.setOption(option);
    },
    TOPecharts(data) {
      var datas = [
        {
          value: data.ms,
          name: "民事",
        },
        {
          value: data.gs,
          name: "供水",
        },
        {
          value: data.dljs,
          name: "道路建设",
        },
        {
          value: data.tc,
          name: "停车",
        },
      ];
      let option = {
        grid: {
          left: 20,
          right: 80,
          bottom: 50,
          top: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return params.data.name + " : " + params.data.value;
          },
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              align: "right",
              textStyle: {
                fontSize: 14,
                color: "rgba(255,255,255,0.5)",
                rich: {
                  name: {
                    padding: [0, 10, 0, 10],
                    width: 100,
                    borderWidth: 1,
                    align: "left",
                  },
                },
              },
              formatter: (name) => {
                var index = datas.map((item) => item.name).indexOf(name) + 1;
                return [
                  "{" + "index" + "|" + "NO." + index + "}",
                  "{name|" + name + "}",
                ].join("");
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: datas.map((item) => item.name),
          },
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              margin: 30, //右侧y轴数字的外边距
              textStyle: {
                fontSize: 14,
                color: "rgba(255,255,255,0.5)",
              },
              formatter: (value) => {
                return value;
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: datas.map((item) => {
              console.log("item", item);
              return item.value;
            }),
          },
        ],
        series: [
          {
            name: "值",
            type: "bar",
            zlevel: 1,
            showBackground: true,
            // 柱状条上方的白色线条
            label: {
              show: true,
              position: "right", // 位置
              color: "#fff",
              fontSize: 19,
              fontWeight: "bold",
              distance: -1, // 字与条形图bar 的距离
              formatter: "|", // 这里是数据展示的时候显示的数据
            },
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#5A3FFF",
                  },
                  {
                    offset: 1,
                    color: "#1ED6FF",
                  },
                ]),
              },
            },
            barWidth: 20,
            data: datas,
          },
        ],
      };
      const topdom = document.getElementById("topEcharts");
      const myCharttop = this.$echarts.init(topdom);
      myCharttop.setOption(option);
    },
    // 点击
    tabClick(index) {
      this.typeIndex = index;
      this.getbusinessSummary(this.typeIndex);
    },
    // 第一
    async getbusinessSummary(index) {
      const res = await businessSummary(index);
      if (res.data.code == 200) {
        this.summary = res.data.data;
      }
    },
    // 第三
    async geteventList() {
      const res = await eventList(1, 20);
      if (res.data.code == 200) {
        this.eventlist = res.data.rows;
      }
    },
    async initalData() {
      const half = await halfYear();
      this.option3.xAxis.data = half.data.data.map((i) => {
        return i.month;
      });
      this.option3.series[0].data = half.data.data.map((i) => {
        return i.input;
      });
      this.option3.series[1].data = half.data.data.map((i) => {
        return i.output;
      });
      const dom3 = document.getElementById("homeEchart3");
      const myChart3 = this.$echarts.init(dom3);
      myChart3.setOption(this.option3);
    },
    // 公共接口
    async getstaticList(type) {
      const res = await staticFn(type);
      if (res.data.code == 200) {
        if (type == "summary_rural_indicator_data") {
          this.indexList = res.data.data;
        }
        if (type == "real_time_epidemic_data") {
          this.yiqingList = res.data.data;
        }
      }
    },
    // 疫苗
    async InitYiqingData() {
      let dataAxis = ["第一针", "第二针", "第三针", "加强针"];
      let data = ["", "", "", ""];
      let yMax = 1000;
      let dataShadow = [];
      const res = await yimiao();
      if (res.data.code == 200) {
        let value = res.data.data;
        data[0] = value.first;
        data[1] = value.second;
        data[2] = value.third;
        data[3] = value.boosterJab;
      }
      let myEcharts = this.$echarts.init(
        document.getElementById("yimiaoechtas")
      );
      for (let i = 0; i < data.length; i++) {
        dataShadow.push(yMax);
      }
      let option = {
        grid: {
          left: "10%",
          right: "10%",
          bottom: "0%",
          containLabel: true,
        },
        tooltip: {
          type: "item",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            color: "rgba(222, 235, 255, 1)",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          textStyle: {
            fontSize: 12,
          },
          z: 1,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "rgba(222, 235, 255, 1)",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(110, 118, 158, 1)",
              type: "solid",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: true,
            barMaxWidth: 30,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(66, 170, 242, 0.5)",
                    },
                    {
                      offset: 1,
                      color: "rgba(4, 243, 247, 1)",
                    },
                  ],
                },
                barBorderRadius: [10, 10, 0, 0],
                label: {
                  show: false, //开启显示
                },
              },
            },
            data: data,
          },
        ],
      };
      myEcharts.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
@keyframes fadeinR {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeinL {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .fixbanner {
    z-index: 888;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.8) 55%);
    @include pt(90);
    // @include pl(20);
    // @include pr(20)
  }
  .tourism_data {
    pointer-events: auto;
    color: #fff;
    @include wd(483);
    @include height(188);
    @include backimage("@/assets/rural/left1.png");
    @include mt(75);
    position: relative;
    animation: fadeinL 2s;
    .tourism_content {
      position: absolute;
      @include top(25);
      left: 0;
      right: 0;
      bottom: 0;
      @include pl(15);
      @include pr(15);
      @include pt(20);
      .select_Bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include mr(32);
        @include mt(10);
        .bar {
          @include wd(52);
          @include height(20);
          border: 0.1vw solid #448ee4;
          @include font(12);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #5aa8f0;
          text-align: center;
          @include lh(20);
          @include ml(9);
          cursor: pointer;
        }
        .active {
          background: rgba(68, 142, 228, 0.2);
          color: #fff;
        }
      }
      .tourism-row {
        width: 90%;
        padding: 0 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mt(15);
      }
      .tourism-col {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include mb(55);
        img {
          @include wd(74);
          @include height(49);
          @include ml(10);
        }
        .tips {
          color: #aed4d4;
          @include font(12);
        }
      }
      .data {
        @include font(22);
        color: #04f5f7;
        font-weight: bold;
      }
      .dot {
        display: inline-block;
        @include wd(6);
        @include height(6);
        border-radius: 50%;
        background-color: #ff9141;
        box-shadow: 0.2vw 0.2vw 0.5vw 0 rgba(255, 145, 65, 0.8);
        @include mr(10);
        @include font(14);
      }
      .dot1 {
        background: #2fa4ff;
        box-shadow: 0.2vw 0.2vw 0.5vw 0 rgba(47, 164, 255, 0.8);
      }
    }
  }
}
.left_titles {
  position: absolute;
  @include top(10);
  left: 10%;
  color: #04f3f7;
  @include font(18);
  font-weight: bold;
  font-style: italic;
}
.right_tiles {
  position: absolute;
  @include top(10);
  right: 10%;
  color: #04f3f7;
  @include font(18);
  font-weight: bold;
  font-style: italic;
}
.littel_type {
  @include font(14);
  @include lh(30);
  @include wd(96);
  @include height(40);
  @include ml(10);
  @include pl(10);
  text-align: left;
  white-space: nowrap;
  background-image: url("@/assets/smartTour/title_bg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}
.blackform {
  animation: fadeinR 2s;
  position: relative;
  @include wd(485);
  @include height(245);
  @include backimage("@/assets/rural/right3.png");
  @include pt(20);
  @include pl(20);
  @include mt(30);
  #yimiaoechtas {
    width: 100%;
    height: 90%;
  }
}
.rutalIndex {
  color: #fff;
  position: relative;
  @include wd(483);
  @include height(285);
  @include backimage("@/assets/rural/left2.png");
  @include pt(60);
  @include mt(50);
  animation: fadeinL 2s;
  .list {
    @include ml(30);
    @include mr(30);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .t_l_i {
      width: 22%;
      position: relative;
      white-space: nowrap;
      color: rgba(202, 209, 255, 1);
      @include font(14);
      @include mb(40);
      text-align: center;
      .iamges {
        @include wd(70);
        @include height(70);
        border-radius: 50%;
        position: relative;
        margin: 0 auto;
        @include mb(10);
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        span {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
.hotlist {
  position: relative;
  @include wd(483);
  @include height(300);
  @include backimage("@/assets/rural/left3.png");
  @include pt(60);
  @include mt(40);
  animation: fadeinL 2s;
}
.trtables {
  height: 90%;
  @include pt(10);
  @include pl(50);
  @include pr(50);
  box-sizing: border-box;
  overflow-y: scroll;
  th,
  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include height(40);
    @include lh(40);
    text-align: center;
  }
  th {
    color: #04f3f7;
    @include font(14);
  }
  tr {
    color: #dffdff;
    @include font(12);
  }
  td {
    width: 25%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .iamgs {
    display: inline-block;
    @include wd(24);
    @include height(24);
    @include br(2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.yiqingcontent {
  position: relative;
  @include wd(485);
  @include height(290);
  @include backimage("@/assets/rural/right1.png");
  @include pt(60);
  color: #fff;
  animation: fadeinR 2s;
  .yiqing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    @include pr(10);
    @include pl(10);
    .bars {
      width: 20%;
      @include height(55);
      border: 0.1vw solid #4ee4ff;
      box-shadow: 0px 0px 0.7vw 0vw rgba(19, 207, 254, 0.6);
      @include br(5);
      @include mb(10);
      @include font(12);
      @include pt(16);
    }
    .data {
      @include font(18);
      color: #04f5f7;
      font-weight: bold;
      @include mt(12);
    }
    .tips {
      color: #aed4d4;
      @include font(10);
    }
  }
}
.formIn {
  animation: fadeinR 2s;
  position: relative;
  @include wd(485);
  @include height(250);
  @include mt(30);
  @include backimage("@/assets/rural/right2.png");
  @include pt(45);
  color: #fff;
  .homeEchart3 {
    @include ml(20);
    width: 90%;
    height: 80%;
  }
}
#topEcharts,
#hotIndexEcharts,
#qinganEcharts,
#coverEcharts {
  @include wd(450);
  @include height(280);
}
::-webkit-scrollbar {
  border-radius: 5px;
  height: 10px;
  background-color: transparent;
  display: none !important;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  display: none !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: transparent;
  display: none !important;
}
.dang_circle {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    @include wd(184);
    @include height(154);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }

  .title {
    color: #fff;
    @include font(18);
    position: absolute;
    top: 22%;
    left: 23%;
  }

  .nums {
    @include wd(100);
    @include height(50);
    @include font(30);
    font-weight: bold;
    color: #fdbf46;
    background: linear-gradient(0deg, #faff67 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-35%, -45%);
  }
}
.dialogcontent{
  border: 1px solid #04b8bb;
  padding: 20px;
  background-color: rgba(40, 72, 77, 0.3);
  .flexs{
    display: flex;
    justify-content: space-between;
  }
  .title{
    color: #04F3F7;
  }
  img{
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}
</style>