export function getdate () {
    let dt = new Date()
    var y = dt.getFullYear();
    var mt = formData(dt.getMonth()+1);
    var day = formData(dt.getDate());
    let week = dt.getDay()
    let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
    ]
    return y + '/' + mt+ '/' + day + ' ' + ' '+ weekArr[week]
}
export function gethours () {
    let dt = new Date()
    var h = formData(dt.getHours())
    var m = formData(dt.getMinutes())
    var s = formData(dt.getSeconds())
    return h + ':' + m + ':' +s
}
// 格式化数据
export function formData (value){
    if(value < 10){
        value = '0'+ value
    }
    return value
}
// 时间戳转字符串
export function formatDate (date,format) {
    var date = new Date(date);
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    switch(format){
        case 'YY-MM-DD':
            return YY + MM + DD
        case 'MM-DD':
            return MM + DD
        default:
         return YY + MM + DD +" "+hh + mm + ss;   
    }
}
// 获取过去七天事件
export function  getFutureDates(){
    const currentDate = new Date();
    const sevenDaysArray = [];
    for (let i = 6; i >= 0; i--) {
        let previousDate = new Date(currentDate);
        previousDate.setDate(currentDate.getDate() - i);
        sevenDaysArray.push(formatDate(previousDate,'MM-DD'));
    }
    return sevenDaysArray
}