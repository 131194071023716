import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/index.vue'
import Index from '../views/visualBoard/Index.vue'
import Tourism from '../views/visualBoard/Tourism.vue'
import townScreen from '../views/visualBoard/townScreen.vue'
import ruralVitalization from '../views/visualBoard/ruralVitalization.vue'
import RenrenTong from '../views/visualBoard/RenrenTong.vue'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  // 智慧旅游
   {
    path: '/tourism',
    name: 'Tourism',
    component: Tourism
  },
  // 一镇一屏
   {
    path: '/townScreen',
    name: 'townScreen',
    component: townScreen
  },
  // 乡村振兴
   {
    path: '/ruralVitalization',
    name: 'ruralVitalization',
    component: ruralVitalization
  },
  // 人人通
   {
    path: '/RenrenTong',
    name: 'RenrenTong',
    component: RenrenTong
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
